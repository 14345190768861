import React from 'react';
import {Dialog, DialogContentText, DialogContent, DialogTitle, DialogActions, Button} from "@material-ui/core";
import './InfoModal.css';
import {useLanguageContext} from "../../../Context/LanguageContext";

export const InfoModal = (props) => {

    const {type, closeInfoModal, openInfoModal} = props

    const {actualLanguage} = useLanguageContext();

    let title;
    let text;

    if(type === 'Avís legal'){
        if(actualLanguage === 'cat'){
            title = 'Avís Legal'
            text =
                <div>
                    <p>La meva benvinguda a aquest espai web, aquí podrà trobar tota la informació relacionada amb el termes i condicions legals que defineixen les relacions entre els usuaris i la responsable de la web. Com usuari, és important que conegui aquests termes abans de continuar amb la navegació.
                    </p>

                    <p>
                        <strong>Responsable:</strong> SomPsic. <br/>
                        <strong>Direcció:</strong>Av, Cornellà nº 2, ent 1ª, 08950, Esplugues de Llobregat, Barcelona. <br/>
                        <strong>Telèfon:</strong> 667558101.<br/>
                        <strong>Correu Electrònic:</strong> psicología@sompsic.com. <br/>
                        <strong>Activitat:</strong> Gabinet de Psicologia.<br/>
                    </p>

                    <p>SOMPSIC, com a responsable de la web, es compromet a tractar la informació dels usuaris i clients amb total garantia i complir amb els requisits nacionals i europeus que regulen la recopilació i ús de les dades de caràcter personal dels usuaris.<br/>
                        La present web, compleix amb el Reglament (UE) 2016/679 del Parlament Eurpoeu i del Cosnell de 27 d’abril de 2016 relatiu a la protecció de les persones físiques (RGPD), així com amb la Llei 34/2002, de 11 de juliol, de Serveis de la Societat de la Informació i Comerç Electrònic (LSSICE ó LSSI).</p>


                    <p><strong>CONDICIONS GENERALS D’ÚS:</strong> <br/>


                        Les presents condicions generals regulen l’ús de la web, inclosos els continguts, productes i serveis posats a disposició en la mateixa. Tota persona que accedeixi a la web, www.sompsic.com, accepta sotmetre’s a les Condicions Generals vigents en cada moment en el portal.
                    </p>


                    <p><strong>DADES DE CARÀCTER PERSONAL</strong> <br/>
                        Llegir política de privacitat.
                    </p>

                    <p>La responsable de SomPsic, per tant, del tractament de les dades, és Joana Clemares Hernàndez.</p>

                    <p><strong>OBLIGACIONS DELS USUARIS: </strong> <br/>
                        L’usuari queda informat, i accepta, que l’accés a la present web no suposa, de ninguna manera, l’inici d’una relació comercial i/o contractual amb la responsable. D’aquesta manera, l’usuari es compromet a utilitzar el lloc web, els seus serveis i continguts sense contravenir la legislació vigent, la bona fe i l’ordre públic.<br/>
                        Queda prohibit l’ús de l web, amb finalitats il·lícites o lesives, o que, de qualsevol manera, puguin causar prejudici o impedir el normal funcionament del lloc web. Respecte dels continguts d’aquesta web, es prohibeix:<br/>
                        • La seva reproducció, distribució o modificació, total o parcial, a menys que es compti amb la nostra autorització com a legitim titular;<br/>
                        • Qualsevol vulneració dels drets del prestador.<br/>
                        • La seva utilització per a finalitats comercials o publicitàries.<br/>

                        En la utilització de la web, l’usuari es compromet a no portar a terme ninguna conducta que pogués fer malbé la imatge, els interessos i els drets de la responsable, o de tercer o que poguessin fer malbé, inutilitzar o sobrecarregar el portal o que impedís, de qualsevol manera, la normal utilització de la web.<br/>

                        No obstant, l’usuari ha de ser conscient de que les mesures de seguretat dels sistemes informàtics en Internet no són enterament fiables i que, per tant, la responsable, no pot garantir la inexistència de malware o altres elements que puguin produir alteracions en els sistemes informàtics de l’usuari o en els seus documents electrònics i fitxers continguts en els mateixos.
                    </p>

                    <p><strong>DRETS DE PROPIETAT INTEL·LECTUAL I INDUSTRIAL:</strong> <br/>
                        En virtut del disposat en els articles 8 i 32.1, paràgraf segon, de la Llei de Propietat Intel·lectual, queden expressament prohibides la reproducció, la distribució i la comunicació pública, inclosa la seva modalitat de posada a disposició, de la totalitat o part dels continguts d’aquesta pàgina web, amb finalitat comercials, en qualsevol suport i per qualsevol mitja tècnic, sense la autorització de la responsable de la web. L’usuari es compromet a respectar els drets de Propietat Intel·lectual i Industrial titularitat de la responsable.<br/>
                        L’usuari coneix i accepta que la totalitat del lloc web, contenint sense caràcter exhaustiu el text, software, continguts (incloent estructura, selecció, ordenació i presentació dels mateixos) podcast, fotografies, material audiovisual i gràfics, està protegida per marques, drets d’autor i altres drets legítims, d’acord, amb els tractes internacionals en els que Espanya és part i altres drets de propietat i lleis d’Espanya.
                    </p>

                    <p><strong>MESURES DE SEGURETAT:</strong> <br/>
                        Les dades personals comunicades per l’usuari a la web o enviades a través de correus electrònics, poden ser emmagatzemades en bases de dades automatitzades o no, on la seva titularitat pertany en exclusiva a SOMPSIC, assumint aquesta totes les mesures d’indole tècnica, organitzativa i de seguretat que garanteixen la confidencialitat, integritat i qualitat de la informació continguda en les mateixes d’acord amb l’establert en la normativa vigent en protecció de dades.<br/>
                        La comunicació entre usuaris i la responsable, utilitza un canal segur, i les dades transmeses són xifrades gràcies a protocols a https, per tant, es garanteix les millors condicions de seguretat per a què la confidencialitat dels usuaris estigui assegurada.
                    </p>

                    <p><strong>EXCLUSIÓ DE GARANTIES:</strong> <br/>
                        La responsable de la web, no atorga ninguna garantia ni es fa responsable, en ningun cas, dels danys i prejudicis de qualsevol naturalesa que poguessin portar causa de: <br/>
                        • La falta de disponibilitat, manteniment i efectiu funcionament de la web, o dels seus serveis continguts.<br/>
                        • L’existència de malware, programes maliciosos o lesius en els continguts.<br/>
                        • L’ús il·lícit, negligent, fraudulent o contrari a aquest Avís Legal.<br/>
                        • La falta de licitud, qualitat, fiabilitat, utilitat i disponibilitat dels serveis prestats per tercers i posats a disposició dels usuaris en el lloc web.<br/>
                        • El prestador no es fa responsable sota ningun concepte dels danys que poguessin derivar de l’ús il·legal o indegut de la present pàgina web.
                    </p>

                    <p><strong>LLEI APLICABLE I JURISDICCIÓ:</strong> <br/>

                        Les relacions entre la responsable, amb els usuaris dels seus productes i serveis, presents en aquesta web es troben sotmeses a la legislació i jurisdicció espanyoles i als tribunals de Barcelona.
                    </p>
                    <p><strong>CONTACTE :</strong> <br/>
                        En el cas de que un usuari de la web tingués algun dubte sobre les Condicions Legals o volgués realitzar qualsevol comentari sobre la web, si us plau, enviin un correu electrònic a psicologia@sompsic.com.
                    </p>
                </div>

        }else {
            title = 'Aviso Legal'
            text =
                <div>
                    <p>Mi bienvenida a este espacio web, aquí podrá encontrar toda la información relacionada con los términos y condiciones legales que definen las relaciones entre los usuarios y la responsable de la web. Como usuario, es importante que conozca estos términos antes de continuar con la navegación.
                    </p>

                    <p>
                        <strong>Responsable:</strong> SomPsic. <br/>
                        <strong>Dirección:</strong>Av, Cornellá nº 2, ent 1ª, 08950, Esplugues de Llobregat, Barcelona. <br/>
                        <strong>Teléfono:</strong> 667558101.<br/>
                        <strong>Correo Electrónico:</strong> psicología@sompsic.com. <br/>
                        <strong>Actividad:</strong> Gabinete de Psicología.<br/>
                    </p>

                    <p>SOMPSIC, como responsable de la web, se compromete a tratar la información de los usuarios y clientes con total garantía y cumplir con los requisitos nacionales y europeos que regulan la recopilación y uso de los datos de carácter personal de los usuarios.<br/>
                        La presente web, cumple con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas (RGPD), así como con la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico (LSSICE ó LSSI).</p>


                    <p><strong>CONDICIONES GENERALES DE USO:</strong> <br/>

                        Las presentes condiciones generales regulan el uso de la web, incluidos los contenidos, productos y servicios puestos a disposición en la misma. Toda persona que acceda a la web, www.sompsic.com,  acepta someterse a las Condiciones Generales vigentes en cada momento en el portal.
                    </p>


                    <p><strong>DATOS DE CARÁCTER PERSONAL:</strong> <br/>

                        Leer Política de Privacidad.
                    </p>

                    <p>La responsable de SomPsic, por lo tanto del tratamiento de los datos, es Joana Clemares Hernàndez.</p>

                    <p><strong>OBLIGACIONES DE LOS USUARIOS: </strong> <br/>
                        El usuario queda informado, y acepta, que el acceso a la presente web no supone, en modo alguno, el inicio de una relación comercial y/o contractual con la responsable. De esta forma, el usuario se compromete a utilizar el sitio web, sus servicios y contenidos sin contravenir la legislación vigente, la buena fe y el orden público.<br/>
                        Queda prohibido el uso de la web, con fines ilícitos o lesivos, o que, de cualquier forma, puedan causar perjuicio o impedir el normal funcionamiento del sitio web. Respecto de los contenidos de esta web, se prohíbe:<br/>
                        - Su reproducción, distribución o modificación, total o parcial, a menos que se cuente con  nuestra autorización como legítimo titular;<br/>
                        - Cualquier vulneración de los derechos del prestador.<br/>
                        - Su utilización para fines comerciales o publicitarios.<br/>
                        En la utilización de la web, el usuario se compromete a no llevar a cabo ninguna conducta que pudiera dañar la imagen, los intereses y los derechos de la responsable, o de terceros o que pudiera dañar, inutilizar o sobrecargar el portal o que impidiera, de cualquier forma, la normal utilización de la web.<br/>
                        No obstante, el usuario debe ser consciente de que las medidas de seguridad de los sistemas informáticos en Internet no son enteramente fiables y que, por tanto, la responsable, no puede garantizar la inexistencia de malware u otros elementos que puedan producir alteraciones en los sistemas informáticos del usuario o en sus documentos electrónicos y ficheros contenidos en los mismos.
                    </p>

                    <p><strong>DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL:</strong> <br/>
                        En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de la responsable de la web. El usuario se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de la responsable.<br/>
                        El usuario conoce y acepta que la totalidad del sitio web, conteniendo sin carácter exhaustivo el texto, software, contenidos (incluyendo estructura, selección, ordenación y presentación de los mismos) podcast, fotografías, material audiovisual y gráficos, está protegida por marcas, derechos de autor y otros derechos legítimos, de acuerdo con los tratados internacionales en los que España es parte y otros derechos de propiedad y leyes de España.
                    </p>

                    <p><strong>MEDIDAS DE SEGURIDAD:</strong> <br/>
                        Los datos personales comunicados por el usuario a la web o enviados a través de correos electrónicos, pueden ser almacenados en bases de datos automatizadas o no, cuya titularidad pertenece en exclusiva a SOMPSIC, asumiendo ésta todas las medidas de índole técnica, organizativa y de seguridad que garantizan la confidencialidad, integridad y calidad de la información contenida en las mismas de acuerdo con lo establecido en la normativa vigente en protección de datos.<br/>
                        La comunicación entre los usuarios y la responsable, utiliza un canal seguro, y los datos transmitidos son cifrados gracias a protocolos a https, por tanto, se garantiza las mejores condiciones de seguridad para que la confidencialidad de los usuarios esté asegurada.
                    </p>

                    <p><strong>EXCLUSIÓN DE GARANTÍAS:</strong> <br/>
                        La responsable de la web, no otorga ninguna garantía ni se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran traer causa de:<br/>
                        -La falta de disponibilidad, mantenimiento y efectivo funcionamiento de la web, o de sus servicios y contenidos.<br/>
                        -La existencia de malware, programas maliciosos o lesivos en los contenidos.<br/>
                        -El uso ilícito, negligente, fraudulento o contrario a este Aviso Legal.<br/>
                        -La falta de licitud, calidad, fiabilidad, utilidad y disponibilidad de los servicios prestados por terceros y puestos a disposición de los usuarios en el sitio web.<br/>
                        -El prestador no se hace responsable bajo ningún concepto de los daños que pudieran dimanar del uso ilegal o indebido de la presente página web.
                    </p>

                    <p><strong>LEY APLICABLE Y JURISDICCIÓN:</strong> <br/>

                        Las relaciones entre la responsable, con los usuarios de sus productos y servicios, presentes en esta web se encuentran sometidas a la legislación y jurisdicción españolas y a los tribunales de Barcelona.
                    </p>
                    <p><strong>CONTACTO :</strong> <br/>
                        Para el caso en que un usuario de la web tuviese alguna duda sobre las Condiciones Legales o quisiera realizar cualquier comentario sobre la web, por favor envíen un correo electrónico a psicología@sompsic.com.
                    </p>
            </div>

            }
        }else if (type === 'Política de privacitat'){
            if(actualLanguage === 'cat'){
                title = 'Política de Privacitat'
                text =
                    <div>
                        <p><strong>MÀXIA TRANSPARÈNCIA AMB ELS USUARIS</strong> <br/>
                            La transparència és el pilar fonamental de la privacitat.  <br/>
                            A efectes del previst a:  <br/>
                            -El Reglament (UE) 2016/679 del Parlament Europeu i del Consell de 27 d’abril de 2016 relatiu a la protecció de les persones físiques en el que respecte al tractament de dades persones i a la lliure circulació d’aquestes dades i pel que es deroga la Directiva 95/46/CE (Reglament general de protecció de dades).  <br/>
                            -La Llei Orgànica de Protecció de Dades Personals i Garantia del Drets Digitals(LOPDGDD) 3/2018 adapta el dret Espanyol al model establer pel Reglament General de Protecció de Dades (RGPD).  <br/>
                            -La  E Llei 34/2002, de 11 de juliol, de Serveis de la Societat de la Informació i Comerç Electrònic (LSSICE ó LSSI). Regula les transaccions econòmiques mitjançant mitjans electrònics.
                        </p>

                        <p><strong>EN AQUESTA WEB ES RESPECTEN ELS SEUS DRETS COM A USUARI I LA SEVA PRIVACITAT </strong> <br/>

                            A Sompsic.com es respecten les dades personals dels usuaris que accedeixen a ella. Els principis que s’apliquen respecte a la privacitat de l’usuari són: <br/>

                            • Mai es comparteix informació personal dels usuaris que accedeixen a la web amb ningun, excepte quan ho exigeixi el compliment de la Llei o en cas de que tingui la seva autorització expressa. <br/>
                            • S’aplica el principi de minimització de dades, en conseqüència únicament es demanen les dades que són necessàries per a poder prestar-li el servei que es requereix o donar resposta a la consulta que es proposa per l’usuari.<br/>
                            • En ningun cas s’utilitzen les seves dades de caràcter personal amb una finalitat diferent a la explicada en aquesta política de privacitat.<br/>

                            La Política de Privacitat d’aquesta web, podrà variar depenent de les exigències legislatives, pel que s’aconsella als usuaris que la visitin de forma periòdica. <br/>

                            La responsable, ha adequat la present web a les exigències del nou Reglament (UE) 2016/679 del Parlament Europeu i del Consell de 27 d’abril de 2016 relatiu a la protecció de les persones físiques (RGPD), així com amb la Llei 34/2002, de 11 de juliol, de Serveis de la Societat de la Informació i Comerç Electrònic (LSSICE O LSSI). <br/>

                            L’ús continuat del lloc darrera la notificació de canvis en les polítiques implicarà que accepta i consenteix aquests canvis. L’animem a visitar i revisar aquesta pàgina amb regularitat.<br/>
                        </p>

                        <p>
                            <strong>Responsable:</strong> SomPsic. <br/>
                            <strong>Direcció:</strong>Av, Cornellá nº 2, ent 1ª, 08950, Esplugues de Llobregat, Barcelona. <br/>
                            <strong>Telèfon:</strong> 667558101.<br/>
                            <strong>Correu Electrònic:</strong> psicología@sompsic.com. <br/>
                            <strong>Activitat:</strong> Gabinet de Psicologia.<br/>
                        </p>

                        <p>La responsable de SomPsic, per tant, del tractament de les dades, és Joana Clemares Hernàndez.</p>

                        <p><strong>PRINCIPIS APLICABLES A LA INFORMACIÓ PERSONAL DELS USUARIS</strong> <br/>

                            El nou Reglament europeu de protecció de dades estableix que s’hauran d’aplicar els següents principis <br/>

                            -Principi de licitud, lleialtat i transparència: en tot moment requerirem el seu consentiment per al tractament de les seves dades personals per a un o varis fins específics dels que l’informarem prèviament amb total transparència.<br/>

                            -Principi de limitació de termini de conservació: les dades seran mantingudes durant no més temps del necessari per a les finalitats del tractament, en funció a la finalitat.<br/>

                            -Principi de minimització: només li sol·licitaran les dades estrictament necessàries en relació amb les finalitats per a els que les requerim. <br/>

                            -Principi d’integritat i confidencialitat: les seves dades seran tractades de tal manera que es garanteix una seguretat adequada i es garanteix la confidencialitat de les mateixes.
                        </p>


                        <p><strong>COM OBTENIM LES SEVES DADES.</strong> <br/>

                            Les dades de caràcter personal que s’obtinguin en aquesta web provenen de:<br/>
                            • Formulari de contacte.<br/>
                            • A través dels emails que vostè pugui enviar-nos.<br/>
                            • A través de les trucades telefòniques que vostè realitzi.<br/>
                        </p>

                        <p><strong>DRETS QUE TÉ COM A USUARI DE LA WEB QUAN ENS FACILITA LES SEVES DADES.</strong> <br/>

                            Qualsevol persona té dret a obtenir confirmació sobre si en la present web, estam utilitzant dades personals que el concernint, o no.  <br/>
                            Les persones interessades tenen dret a:<br/>
                            -Sol·licitar l’accés a les dades personals relatives a l’interessat.<br/>
                            -Sol·licitar la seva rectificació o supressió.<br/>
                            -Sol·licitar la limitació del seu tractament.<br/>
                            -Oposar-se al tractament.<br/>
                            -Sol·licitar la portabilitat de les dades.<br/>
                            Els interessats podran accedir a les seves dades personal, així com sol·licitar la rectificació de les dades inexactes o, en el seu cas, sol·licitar la supressió quan, entre altres motius, les dades ja no siguin necessàries per a les finalitats que van ser recollides.<br/>
                            En determinades circumstàncies, els interessats podran sol·licitat la limitació del tractament de les seves dades, en aquest cas únicament es conservaran per a l’exercici o la defensa de reclamacions. <br/>
                            En determinades circumstàncies i per motius relacionats amb la seva situació particular, els interessats podran oposar-se al tractament de les seves dades, en conseqüència la responsable, deixarà de tractar les dades, excepte per motius legítims imperiosos, o l’exercici o la defensa de possible reclamacions. També podran sol·licitar la portabilitat de les seves dades.<br/>
                            Com interessant, té dret a rebre les dades personals que l’inconveicin, que ens hagi facilitat i en un format estructurat, d’ús comú i lectura mecànica, i a transmetre’ls a un alter responsable del tractament quan:<br/>
                            -El tractament estigui basat en el consentiment<br/>
                            -Les dades hagin estat facilitades per la persona interessada.<br/>
                            -El tractament s’efectuï per mitjans automatitzats.<br/>
                            Els interessats també tindran dret a la tutela judicial efectiva i a presentar una reclamació davant l’autoritat de control, en aquest cas, l’Agència Espanyola de Protecció de Dades, si consideren que el tractament de dades personals que el concernint infringeix el Reglament.<br/>
                            Per a exercir tal drets pot posar-se en contacte amb la responsable de la web enviant un correu electrònic a psicologia@sompsic.com. <br/>
                        </p>

                        <p><strong>FINALITATS EN EL TRACTAMENT DE LES SEVES DADES</strong> <br/>

                            Facilitar les seves dades personals a la responsable, en el qüestionari de recollida de dades és voluntari i, en  cas de no fer-ho no ens podrem posar en contacte amb vostè. <br/>
                            SOMPSIC, tractarà les dades personals recollides en el formulari per a les següents finalitats: <br/>

                            A.  Mantenir la comunicació entre ambdues parts.<br/>

                            B. Resoldre les seves consultes, qüestions, preguntes, comentaris i suggeriments. <br/>
                        </p>

                        <p><strong>QUINA ÉS LA LEGIMITZACIÓ PER AL TRACTAMENT DE LES SEVES DADES?</strong> <br/>
                            La base legal per al tractament de les seves dades personals és el consentiment de l’interessat o del seu representant legal al omplir el formulari de la petició o consulta que el mateix realitza o al contactar a través del correu electrònic facilitat a al pàgina.<br/>
                            <strong>Categoria de dades</strong><br/>
                            Les categories de dades que es tracten són dades identificatives, concretament, nom i cognoms, número de telèfon, correu electrònic i direcció IP.<br/>
                        </p>


                        <p><strong>PER QUANT TEMPS CONSERVAREM LES SEVES DADES?</strong> <br/>
                            Es conservaran durant el temps necessari per a complir amb la finalitat per a la que es van recollir i per a determinar les possibles responsabilitats que es poguessin derivar d’aquesta finalitat i del tractament de les dades. Una vegada passat el temps esmentat seran cancel·lades/suprimides d’ofici.<br/>
                        </p>
                        <p><strong>A QUI ES COMUNICARÀN LES SEVES DADES?</strong> <br/>
                            Per a prestar serveis estrictament necessaris per al desenvolupament de l’activitat, www.sompsic.com, comparteix dades am bels següents prestadors sota les seves corresponents condicions de privacitat:<br/>

                            Hosting: DigitalOcean, LLC, com a proveïdor del Hosting de la Web.<br/>

                            Més informació a: https://www.digitalocean.com/legal/privacy-policy/<br/>

                        </p>


                        <p><strong>TRANSFERÈNCIA INTERNACIONAL DE DADES:</strong> <br/>
                            El tractament de les seves dades es realitza, amb caràcter general, per prestadors de serveis ubicats dins de l’Espai Econòmic Europeu o en països que han estat declarats amb un nivell adequat de protecció. En altres supòsits garantim la seguretat i legitimitat del tractament de les seves dades exigent als seus proveïdors que disposin de normes corporatives vinculants que garanteixin la protecció de la informació de manera semblant a les que estableixen les normes europees, que es troben acollides al Privacy Shield, en cas de prestadors de serveis en els EE.UU, o que subscrivint les clàusules de la Unió Europea. En tot moment vetllarem per a què, sigui qui sigui qui té la seva informació per a ajudar-nos a prestar el nostres serveis, ho fa amb totes les garanties. <br/>
                        </p>

                        <p><strong>NAVEGACIÓ</strong> <br/>
                            Al navegar per la web, se poden recollir dades no identificables, que poden incloure, direccions IP, ubicació geografia (aproximadament), un registre de com s’utilitzen els serveis i llocs, i altres dades que no poden ser utilitzades per a identificar l’usuari. <br/>

                        </p>
                        <p><strong>SECRET I SEGURETAT DE LES DADES</strong> <br/>
                            La responsable de la web, es compromet en l’ús i el tractament de les dades incloses personals dels usuaris, respectant la seva confidencialitat i a utilitzar-los d’acord amb la finalitat del mateix, així com donar compliment a la seva obligació de guardar-los i adaptar totes les mesures per a evitar l’alteració, pèrdua, tractament o accés no autoritzat, de conformitat amb l’establert en la normativa vigent de protecció de dades.<br/>
                            Aquesta web inclou un certificat SSL. Es tracta d’un protocol de seguretat que fa que les seves dades viatgin de manera íntegra i segura, és a dir, la transmissió de les dades entre un servidor i usuari web, i en retroalimentació, és totalment xifrada o encriptada.<br/>
                            La responsable, no pot garantir l’absoluta inexpugnabilitat de la xarxa Internet i per tant la violació de les dades mitjançant accessos fraudulents a ells per part de tercers.<br/>
                        </p>

                        <p><strong>VERACITAT DE LES DADES I EXACTITUD DE LES MATEIXES</strong> <br/>

                            L’usuari de la web, serà l’únic responsable de la veracitat i correcció de les dades que es remeteixen a la web, exonerant a la responsable, de qualsevol responsabilitat al respecte.<br/>
                            L’usuari accepta facilitar informació completa i correcta a través del formulari existent a la web. L’usuari garanteix i respon, en qualsevol cas de l’exactitud, autenticitat i vigència de les dades proporcionades.<br/>
                        </p>
                        <p><strong>ACCEPTACIÓ I CONSENTIMENT</strong> <br/>
                            L’usuari declara haver sigut informat de les condicions sobre Protecció de Dades de Caràcter Personal, acceptat i consentint el tractament de les mateixes per part de SomPsic, en la forma i per a les finalitats indicades en la present Política de Privacitat.<br/>
                        </p>
                        <p><strong>DADES PERSONALS DE MENORS</strong> <br/>
                            La web no es dirigeix a menors d’edat. No obstant la responsable, declina qualsevol responsabilitat per a l’incompliment d’aquest requisit per part de l’usuari. Tal i com estableix la normativa vigent. Et recordem que si ets menor de 14 anys precisem que els teus pares o tutors ens donin el seu consentiment per al teu registre a la nostra web, pel que hauràs d’aportar una direcció d’email vàlida per a què puguem contactar amb ells.<br/>
                        </p>
                        <p><strong>REVOCABILITAT</strong> <br/>
                            El consentiment prestat, tant per al tractament com per a la cessió de les dades dels interessats, és revocable en qualsevol moment comunicant-ho a la responsable en els termes establerts en aquesta Política de Privacitat per a l’exercici dels drets ARCO. Aquesta revocació en ningun cas tindrà caràcter retroactiu.<br/>
                        </p>
                        <p><strong>CANVIS EN LA PRESENT POLÍTICA DE PRIVACITAT</strong> <br/>
                            La responsable de la web, es reserva el dret de modificar la present política per adaptar-la a novetats legislatives o jurisprudencials així com a pràctiques de la industria. En aquests supòsits, el Prestador anunciarà en aquesta pàgina els canvis introduïts amb raonable antelació a la seva posada en pràctica.  <br/>
                        </p>
                        <p><strong>CONSULTES O DUBTES SOBRE ELS SEUS DRETS O SOBRE LA POLÍTICA DE PRIVACITAT</strong> <br/>
                            Si té algun comentari o dubte sobre aquesta política o sobre la forma en que s’ha pogut gestionar alguna informació sobre les seves dades de caràcter personal que hagi proporcionat, pot contactar per correu postal a la direcció Av. Cornellà nº 2, entl 1º, 08950, Esplugues de Llobregat, Barcelona  o por correu electrònic enviant un missatge a la direcció: psicologia@sompsic.com. <br/>
                        </p>
                    </div>


            }else{
                title = 'Política de Privacidad'
                text=
                    <div>
                        <p><strong>MÁXIMA TRANSPARENCIA CON LOS USUARIOS</strong> <br/>
                            La transparencia es el pilar fundamental de la privacidad.  <br/>
                            A efectos de lo previsto en:  <br/>
                            -El Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento general de protección de datos).  <br/>
                            -La Ley Orgánica de Protección de Datos Personales y Garantía de los Derechos Digitales(LOPDGDD) 3/2018 adapta el derecho español al modelo establecido por el Reglamento General de Protección de Datos (RGPD).  <br/>
                            -La Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico (LSSICE ó LSSI). Regula las transacciones económicas mediante medios electrónicos.
                        </p>

                        <p><strong>EN ESTA WEB SE RESPETAN SUS DERECHOS COMO USUARIO Y SU PRIVACIDAD</strong> <br/>

                            En Sompsic.com se respetan los datos personales de los usuarios que acceden a ella. Los principios que se aplican respecto a la privacidad del usuario son:<br/>

                            - Nunca se comparte información personal de los usuarios que acceden a la web con nadie, excepto cuando lo exija el cumplimiento de la Ley o en caso de que tenga su autorización expresa. <br/>

                            - Se aplica el principio de minimización de datos, en consecuencia únicamente se piden los datos que son necesarios para poder prestarle el servicio que se requiere o dar respuesta a la consulta que se propone por el usuario.<br/>

                            - En ningún caso se utilizan sus de carácter personal con una finalidad distinta a la explicada en esta política de privacidad.<br/>

                            La Política de Privacidad de esta web, podrá variar dependiendo de las exigencias legislativas, por lo que se aconseja a los usuarios que la visiten de forma periódica. <br/>

                            La responsable, ha adecuado la presente web a las exigencias del nuevo Reglamento  (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas (RGPD), así como con la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico (LSSICE O LSSI).<br/>

                            El uso continuado del sitio tras la notificación de cambios en las políticas implicará que acepta y consiente dichos cambios. Le animamos a visitar y revisar esta página con regularidad.<br/>
                        </p>

                        <p>
                            <strong>Responsable:</strong> SomPsic. <br/>
                            <strong>Dirección:</strong>Av, Cornellá nº 2, ent 1ª, 08950, Esplugues de Llobregat, Barcelona. <br/>
                            <strong>Teléfono:</strong> 667558101.<br/>
                            <strong>Correo Electrónico:</strong> psicología@sompsic.com. <br/>
                            <strong>Actividad:</strong> Gabinete de Psicología.<br/>
                        </p>
                        <p>La responsable de SomPsic, por lo tanto del tratamiento de los datos, es Joana Clemares Hernàndez.</p>
                        <p><strong>PRINCIPIOS APLICABLES A LA INFORMACIÓN PERSONAL DE LOS USUARIOS</strong> <br/>

                            El nuevo Reglamento europeo de protección de datos establece que se deberán aplicar los siguientes principios:<br/>

                            -Principio de licitud, lealtad y transparencia: En todo momento requeriremos su consentimiento para el tratamiento de sus datos personales para uno o varios fines específicos de los que le informaremos previamente con total transparencia.<br/>

                            -Principio de limitación del plazo de conservación: los datos serán mantenidos durante no más tiempo del necesario para los fines del tratamiento, en función a la finalidad.<br/>

                            -Principio de minimización: Solo le solicitarán los datos estrictamente necesarios en relación con los fines para los que los requerimos. <br/>

                            -Principio de integridad y confidencialidad: Sus datos serán tratados de tal manera que se garantice una seguridad adecuada y se garantice la confidencialidad de los mismos.<br/>
                        </p>


                        <p><strong>CÓMO OBTENEMOS SUS DATOS.</strong> <br/>

                            Los datos de carácter personal que se obtienen en esta web proceden de:<br/>
                            -Formulario de contacto.<br/>
                            -A través de los emails que usted pueda enviarnos.<br/>
                            -A través de las llamadas telefónicas que usted realice.<br/>
                        </p>

                        <p><strong>DERECHOS QUE TIENE COMO USUARIO DE LA WEB CUANDO NOS FACILITA SUS DATOS.</strong> <br/>

                            Cualquier persona tiene derecho a obtener confirmación sobre si en la presente web, estamos tratando datos personales que le conciernen, o no.<br/>
                            Las personas interesadas tienen derecho a:<br/>
                            -Solicitar el acceso a los datos personales relativos al interesado.<br/>
                            -Solicitar su rectificación o supresión.<br/>
                            -Solicitar la limitación de su tratamiento.<br/>
                            -Oponerse al tratamiento.<br/>
                            -Solicitar la portabilidad de los datos.<br/>
                            Los interesados podrán acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.<br/>
                            En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente se conservarán para el ejercicio o la defensa de reclamaciones.<br/>
                            En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos, en consecuencia la responsable, dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones. También podrán solicitar la portabilidad de sus datos.<br/>
                            Cómo interesado, tiene derecho a recibir los datos personales que le incumban, que nos haya facilitado y en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos a otro responsable del tratamiento cuando:<br/>
                            -El tratamiento esté basado en el consentimiento<br/>
                            -Los datos hayan sido facilitados por la persona interesada.<br/>
                            -El tratamiento se efectúe por medios automatizados.<br/>
                            Los interesados también tendrán derecho a la tutela judicial efectiva y a presentar una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si consideran que el tratamiento de datos personales que le conciernen infringe el Reglamento.<br/>
                            Para ejercer tales derechos puede ponerse en contacto con la responsable de la web enviando un correo electrónico a psicologia@sompsic.com. <br/>
                        </p>

                        <p><strong>FINALIDADES EN EL TRATAMIENTO DE SUS DATOS</strong> <br/>

                            Facilitar sus datos personales a la responsable, en el cuestionario de recogida de datos es voluntario y, en caso de no hacerlo no nos podremos poner en contacto con Usted. <br/>
                            SOMPSIC, tratará los datos personales recogidos en el formulario para las siguientes finalidades:<br/>

                            A. Mantener la comunicación entre ambas partes.<br/>

                            B. Resolver sus consultas, cuestiones, preguntas, comentarios y sugerencias. <br/>
                        </p>

                        <p><strong>CÚAL ES LA LEGITIMACIÓN PARA EL TRATAMIENTO DE SUS DATOS?</strong> <br/>
                            La base legal para el tratamiento de sus datos personales es el consentimiento del interesado o de su representante legal al rellenar el formulario de la petición o consulta que el mismo realiza o al contactar a través del correo electrónico facilitado en la página.<br/>
                            <strong>Categoría de datos</strong><br/>
                            Las categorías de datos que se tratan son datos identificativos, concretamente, nombre y apellidos, número de teléfono, correo electrónico y dirección IP.<br/>
                        </p>


                        <p><strong>¿POR CUÁNTO TIEMPO CONSERVAREMOS SUS DATOS?</strong> <br/>
                            Se conservarán durante el tiempo necesario para cumplir con la finalidad para la que se recabaron y para determinar las posibles responsabilidades que se pudieran derivar de dicha finalidad y del tratamiento de los datos. Una vez pasado dicho tiempo serán cancelados / suprimidos de oficio.<br/>
                        </p>
                        <p><strong>¿A QUIÉN SE COMUNICARÁN SUS DATOS?</strong> <br/>
                            Para prestar servicios estrictamente necesarios para el desarrollo de la actividad, www.sompsic.com, comparte datos con los siguientes prestadores bajo sus correspondientes condiciones de privacidad:<br/>

                            Hosting: DigitalOcean, LLC, como proveedor del Hosting de la Web.<br/>

                            Más información en: https://www.digitalocean.com/legal/privacy-policy/<br/>

                        </p>


                        <p><strong>TRANSFERENCIA INTERNACIONAL DE DATOS:</strong> <br/>
                            El tratamiento de sus datos se realiza, con carácter general, por prestadores de servicios ubicados dentro del Espacio Económico Europeo o en países que han sido declarados con un nivel adecuado de protección. En otros supuestos garantizamos la seguridad y legitimidad del tratamiento de tus datos exigiendo a sus proveedores que dispongan de normas corporativas vinculantes que garanticen la protección de la información de manera similar a las que establecen las normas europeas, que se encuentren acogidos al Privacy Shield, en caso de prestadores de servicios en los EE. UU., o que suscriban las cláusulas tipo de la Unión Europea. En todo momento velaremos por que, sea quien sea quien tiene su información para ayudarnos a prestar nuestros servicios, lo hace con todas las garantías. <br/>
                        </p>

                        <p><strong>NAVEGACIÓN</strong> <br/>
                            Al navegar por la web, se pueden recoger datos no identificables, que pueden incluir, direcciones IP, ubicación geográfica (aproximadamente), un registro de cómo se utilizan los servicios y sitios, y otros datos que no pueden ser utilizados para identificar al usuario. <br/>

                        </p>
                        <p><strong>SECRETO Y SEGURIDAD DE LOS DATOS</strong> <br/>
                            La responsable de la web, se compromete en el uso y tratamiento de los datos incluidos personales de los usuarios, respetando su confidencialidad y a utilizarlos de acuerdo con la finalidad del mismo, así como a dar cumplimiento a su obligación de guardarlos y adaptar todas las medidas para evitar la alteración, pérdida, tratamiento o acceso no autorizado, de conformidad con lo establecido en la normativa vigente de protección de datos.<br/>
                            Esta web incluye un certificado SSL. Se trata de un protocolo de seguridad que hace que sus datos viajen de manera íntegra y segura, es decir, la transmisión de los datos entre un servidor y usuario web, y en retroalimentación, es totalmente cifrada o encriptada.<br/>
                            La responsable, no puede garantizar la absoluta inexpugnabilidad de la red Internet y por tanto la violación de los datos mediante accesos fraudulentos a ellos por parte de terceros.<br/>
                        </p>

                        <p><strong>VERACIDAD DE LOS DATOS Y EXACTITUD DE LOS MISMOS</strong> <br/>

                            El usuario de la web, será el único responsable de la veracidad y corrección de los datos que remita a la web, exonerando a la responsable,  de cualquier responsabilidad al respecto.<br/>
                            El usuario acepta facilitar información completa y correcta a través del formulario existente en la web. El usuario garantiza y responde, en cualquier caso de la exactitud, autenticidad y vigencia de los datos proporcionados.<br/>
                        </p>
                        <p><strong>ACEPTACIÓN Y CONSENTIMIENTO</strong> <br/>
                            El Usuario declara haber sido informado de las condiciones sobre Protección de Datos de Carácter Personal, aceptando y consintiendo el tratamiento de los mismos por parte de SomPsic, en la forma y para las finalidades indicadas en la presente Política de Privacidad.<br/>
                        </p>
                        <p><strong>DATOS PERSONALES DE MENORES</strong> <br/>
                            La web no se dirige a menores de edad. No obstante la responsable, declina cualquier responsabilidad por el incumplimiento de este requisito por parte del Usuario. Tal y como establece la normativa vigente. Te recordamos que si eres menor de 14 años precisamos que tus padres o tutores nos den su consentimiento para tu registro en nuestra web, por lo que deberás aportar una dirección de email válida para que podamos contactar con ellos.<br/>
                        </p>
                        <p><strong>REVOCABILIDAD</strong> <br/>
                            El consentimiento prestado, tanto para el tratamiento como para la cesión de los datos de los interesados, es revocable en cualquier momento comunicándolo a la responsable en los términos establecidos en esta Política para el ejercicio de los derechos ARCO. Esta revocación en ningún caso tendrá carácter retroactivo.<br/>
                        </p>
                        <p><strong>CAMBIOS EN LA PRESENTE POLÍTICA DE PRIVACIDAD</strong> <br/>
                            La responsable de la web, se reserva el derecho a modificar la presente política para adaptarla a novedades legislativas o jurisprudenciales así como a prácticas de la industria. En dichos supuestos, el Prestador anunciará en esta página los cambios introducidos con razonable antelación a su puesta en práctica.<br/>
                        </p>
                        <p><strong>CONSULTAS O DUDAS SOBRE SUS DERECHOS O SOBRE LA POLÍTICA DE PRIVACIDAD</strong> <br/>
                            Si tiene algún comentario o duda sobre esta política o sobre la forma en que se ha podido gestionar alguna información sobre sus datos de carácter personal que haya proporcionado, puede contactar por correo postal en la dirección Av. Cornellá nº 2, entl 1º, 08950, Esplugues de Llobregat, Barcelona  o por correo electrónico enviando un mensaje  a la dirección: psicologia@sompsic.com. <br/>
                        </p>
                    </div>

            }
    }else if (type === 'Política de cookies'){
        if(actualLanguage === 'cat') {
            title = 'Política de Cookies'
            text =
                <div>
                    <p>SOMPSIC informa que aquesta pàgina web no utilitza cookies.</p>
                </div>
        }else {
            title = 'Política de Cookies'
            text =
                <div>
                    <p>SOMPSIC informa que esta página web no utiliza cookies.</p>
                </div>
        }
    }

    return(
        <Dialog open={openInfoModal} onClose={closeInfoModal} scroll={'body'}>
            <DialogTitle className={'box-dialog-title'}><strong>{title}</strong></DialogTitle>
            <DialogContent className={'box-dialog-content'}>
                <DialogContentText className={'box-dialog-text'}>

                        {text}


                </DialogContentText>
            </DialogContent>
            <DialogActions className={'box-dialog-actions'}>
                <Button onClick={() => closeInfoModal()}>
                    {(actualLanguage === 'cat') ? <strong>Tancar</strong> : <strong>Cerrar</strong>}
                </Button>
            </DialogActions>
        </Dialog>
    )
}