import React from "react"
import "./HeaderContact.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {useTitlesContext} from "../../../../Context/TitlesContext";
import {findTitle} from "../../../../Functions/FindTitle";
import {useLanguageContext} from "../../../../Context/LanguageContext";

export const HeaderContact = props =>{

    const {contactInformation} = props

    const {actualLanguage} = useLanguageContext()
    const {titles} = useTitlesContext()

    const ifaPhone = <FontAwesomeIcon className="icon icon-size-phone-contact" icon={faPhone}/>
    const ifaEnvelope = <FontAwesomeIcon className="icon icon-size-envelope-contact" icon={faEnvelope}/>

    const location = [
        {icon: ifaEnvelope, text: "psicologia@sompsic.com"},
        {icon: ifaPhone, text: "667558101"}
    ]

    return(
        <div className={"box-header-contact"}>
            {contactInformation &&
            <div className={"box-header-contact-left"}>
                <div>
                    <h4>{contactInformation[0][`title_${actualLanguage}`]}</h4>
                    <p>{contactInformation[0][`text_${actualLanguage}`]}</p>

                </div>
                <div>
                    <h4>{findTitle(titles, "contact", actualLanguage).toUpperCase()}</h4>
                    <div>
                        {location.map(dataLocation => {
                            return(
                                <div className={"context-header-contact"}>
                                    <div className={"box-context-contact"}>
                                        <div>{dataLocation.icon}</div>
                                        <p>{dataLocation.text}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            }
            <div className={"line-header-contact-explication"}>{""}</div>
            {contactInformation &&
            <React.Fragment>
                <a id="zl-url" className="zl-url"
                   href="https://www.doctoralia.es/joana-clemares-hernandez/psicologo-psicologo-infantil/esplugues-de-llobregat"
                   rel="nofollow" data-zlw-doctor="joana-clemares-hernandez" data-zlw-type="big_with_calendar"
                   data-zlw-opinion="false" data-zlw-hide-branding="true">Joana Clemares Hernández - Doctoralia.es</a>
                {!function($_x,_s,id){
                    let js,fjs=$_x.getElementsByTagName(_s)[0];
                    js = $_x.createElement(_s);
                    js.id = id;
                    js.src = "//platform.docplanner.com/js/widget.js";
                    fjs.parentNode.insertBefore(js,fjs);
                }(document,"script","zl-widget-s")}
            </React.Fragment>
            }
            {/*<div className={"box-header-contact-right"}>
                <FormAppointment backgroundColor={"green"}/>
            </div>*/}

        </div>
    )
}