import React from "react";
import "./RightContext.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import {useLanguageContext} from "../../../../Context/LanguageContext";
import {useTitlesContext} from "../../../../Context/TitlesContext";
import {findTitle} from "../../../../Functions/FindTitle";

export const RightContext = props => {

    const {dataRight, idText} = props

    const {actualLanguage} = useLanguageContext()
    const {titles} = useTitlesContext()


    const ifaCheck = <FontAwesomeIcon className="icon icon-size-check-right" icon={faCheck}/>

    return(
        <div className={"box-right"}>
            <div className={"box-title-right"}>
                <p className={"title-right-text"}>{findTitle(titles, "howWeDeal", actualLanguage).toUpperCase()}</p>
                <div className={"line-title-h4"}>{""}</div>
            </div>
            <div className={"box-right-context"}>
                {Object.keys(dataRight).map(key =>{
                    return(
                        <div>
                            {(idText === key) &&
                                <div className={"grid-right-text"}>
                                    {dataRight[key].map(data => {
                                        return(
                                            <div className={"box-right-text"}>
                                                <div>{ifaCheck}</div>
                                                <div><p className={"right-text"}>{data[`text_${actualLanguage}`]}</p></div>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    )
}