import React, { useEffect, useState} from "react";
import {TreatmentLabel} from "./TreatmentLabel/TreatmentLabel"
import "./Treatments.css"
import {useLanguageContext} from "../../../../Context/LanguageContext";
import {useTitlesContext} from "../../../../Context/TitlesContext";
import {findTitle} from "../../../../Functions/FindTitle";
import {cardFlipTreatmentsDictionary} from "../../../../dictionaries/cardTreatments/cardFlipTreatments";

export const Treatments = () => {

    const [dataTreatmentLabel, setDataTreatmentLabel] = useState(undefined);

    useEffect(()=> {
        setDataTreatmentLabel(cardFlipTreatmentsDictionary);
    }, []);

    const {actualLanguage} = useLanguageContext();
    const {titles} = useTitlesContext();

    return (
        <div className={"container"}>
            <h3 className={"treatments-title"}>{findTitle(titles, "treatments", actualLanguage).toUpperCase()}</h3>
            <div className={"line-title-h3"}>{""}</div>
            {dataTreatmentLabel && <TreatmentLabel dataTreatmentLabel={dataTreatmentLabel}/>}
        </div>
    )
}