export const frequentQuestionsDictionary = [
    {
        "id": 1,
        "question_cat": "Qu\u00e8 \u00e9s la psicologia cl\u00ednica o psicoter\u00e0pia?",
        "question_es": "Que es la psicolog\u00eda cl\u00ednica o psicoterapia?",
        "paragraphOne_cat": "La psicologia cl\u00ednica o psicoter\u00e0pia consisteix en avaluar, diagnosticar, tractar i prevenir tots aquells factors que tenen algun efecte a la salut mental de les persones i a la seva capacitat adaptativa, generant malestar subjectiu i un patiment a la persona.",
        "paragraphOne_es": "La psicologia cl\u00ednica o psicoterapia consiste en evaluar, diagnosticar, tratar y prevenir todos los factores que tengan alg\u00fan efecto en la salud mental de las personas y en su capacidad adaptativa, generando malestar subjectivo y sufrimiento a la persona.",
        "paragraphTwo_cat": "",
        "paragraphTwo_es": ""
    },
    {
        "id": 2,
        "question_cat": "Qu\u00e8 fa un psic\u00f2leg cl\u00ednic?",
        "question_es": "Que hace un psic\u00f3logo cl\u00ednico?",
        "paragraphOne_cat": "El psic\u00f2leg cl\u00ednic \u00e9s aquell professional de la psicologia especialitzat en salut mental. La seva funci\u00f3 \u00e9s avaluar, diagnosticar i tractar problemes i/o trastorns psicol\u00f2gics o qualsevol aspecte que generi malestar.",
        "paragraphOne_es": "El psic\u00f3logo cl\u00ednico es aquel profesional de la psicolog\u00eda especializado en salud metal. Su funci\u00f3n es evaluar, diagnosticar y tratar problemas y/o trastornos psicol\u00f3gicos o cualquier aspecto que genere malestar.",
        "paragraphTwo_cat": "",
        "paragraphTwo_es": ""
    },
    {
        "id": 3,
        "question_cat": "He d\u2019anar al psic\u00f2leg?",
        "question_es": "Tengo que ir al psic\u00f3logo?",
        "paragraphOne_cat": "Es recomana acudir a psicoter\u00e0pia quan la persona tingui un problema que li generi malestar i/o no es trobi a gust amb ella mateixa, ja sigui per un problema emocional o f\u00edsic. Anar a ter\u00e0pia \u00e9s una forma de cuidar-se a un mateix i dedicar-se l\u2019atenci\u00f3 necess\u00e0ria per a sentir-se b\u00e9 amb un mateix.",
        "paragraphOne_es": "Se recomienda acudir a psicoterapia cuando la persona tenga un problema que le genere malestar y/o no se encuentre a gusto con si misma, ya sea por un problema emocional o f\u00edsico. Ir a terapia es una forma de cuidarse a uno mismo i dedicarse la atenci\u00f3n necesaria para sentirse bien con uno mismo.",
        "paragraphTwo_cat": "",
        "paragraphTwo_es": ""
    },
    {
        "id": 4,
        "question_cat": "Quant dura una ter\u00e0pia?",
        "question_es": "Cuanto dura una terapia?",
        "paragraphOne_cat": "Tot dep\u00e8n de la problem\u00e0tica a tractar i de la implicaci\u00f3 i inter\u00e8s de la persona per a millorar.",
        "paragraphOne_es": "Todo depende de la problem\u00e1tica a tratar y de la implicaci\u00f3n e inter\u00e9s de la persona para mejorar.",
        "paragraphTwo_cat": "Per exemple, per a tractar un problema d\u2019ansietat, la ter\u00e0pia pot durar entre 8 i 15 sessions i per a una depressi\u00f3, la ter\u00e0pia pot durar entre 14 i 18 sessions, sempre depenent de la implicaci\u00f3 i l\u2019evoluci\u00f3 de la persona.",
        "paragraphTwo_es": "Por ejemplo, para tratar un problema de ansiedad, la terapia puede durar entre 8 y 15 sesiones y, para una depresi\u00f3n, la terapia puede durar entre 14 y 18 sesiones, siempre dependiendo de la implicaci\u00f3n i evoluci\u00f3n de la persona."
    }
]