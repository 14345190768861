import React from "react";
import "./AboutUsContext.css"
import {Office} from "./Office/Oficce";
import {useLanguageContext} from "../../../../Context/LanguageContext";
import {useTitlesContext} from "../../../../Context/TitlesContext";
import {findTitle} from "../../../../Functions/FindTitle";
import mesa from '../../../../Img/mesa.jpg'

export const AboutUsContext = props => {

    const {dataAboutUs} = props

    const {actualLanguage} = useLanguageContext()
    const {titles} = useTitlesContext()

    return (
        <div>
            <h3 className={"title-aboutUs-view"}>{findTitle(titles, "aboutUs", actualLanguage).toUpperCase()}</h3>
            <div className={"line-title-h3"}>{""}</div>
            <div className={"header-photo-aboutUs"}>
                <img src={mesa} alt="mesa"/>
            </div>
            <h3 className={"title-aboutUs-help"}>{findTitle(titles, "weWantToHelp", actualLanguage).toUpperCase()}</h3>
            <div className={"line-title-h3"}>{""}</div>
            <div className={"box-help"}>
                <div className={"context-help-text"}>
                   <p>{dataAboutUs[0][`helpParagraphOne_${actualLanguage}`]}</p>
                   <p>{dataAboutUs[0][`helpParagraphTwo_${actualLanguage}`]}</p>
                </div>
                <div className={"context-help-text"}>
                    <p>{dataAboutUs[0][`helpParagraphThree_${actualLanguage}`]}</p>
                    <p>{dataAboutUs[0][`helpParagraphFour_${actualLanguage}`]}</p>
                </div>
            </div>
            <Office dataAboutUs={dataAboutUs}/>
            {/*<Opinions/>*/}
        </div>
    )
}