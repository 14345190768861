import React, {useEffect} from "react";
import "./ExplicationTherapy.css"
import {RightContext} from "./RightContext/RightContext";
import {CenterContext} from "./CenterContext/CenterContext";
import {scrollToY} from "../../../Functions/AutomaticScroll"

export const ExplicationTherapy = props => {
    
    const {img, title, dataCenter, dataRight, idText, urlParam} = props

    useEffect(() =>{
        if(urlParam !== "all"){
        setTimeout(() =>{
                const elem = document.getElementById('explicationTherapyActive').getBoundingClientRect();
                const header = document.getElementById('header').getBoundingClientRect();
                const additionalSpaceTop = 20;
                const Y = elem.bottom - header.height -elem.height - additionalSpaceTop;
                scrollToY(Y, 1500)
        }, 200)
        }else{
            window.scrollTo(0, 0);
        }
    }, [urlParam]);

    return(
        <div>
            <div className={"box-title-explication-therapy"}>
                <h4>{title.toUpperCase()}</h4>
                <div className={"line-title-h3"}>{""}</div>
            </div>
            <div className={"box-explication-therapy"}>
                <div className={"explication-therapy"}>
                    <div className={"flex-left"}>
                        <div className={"box-img-explication-therapy"}>
                            <img className={"img-explication-therapy"} src={img} alt={title} />
                        </div>
                    </div>
                    <div className={"flex-center"}>
                        <div className={"center-text"}>
                            <CenterContext dataCenter={dataCenter} idText={idText}/>
                        </div>
                    </div>
                    <div className={"flex-right"}>
                        <RightContext dataRight={dataRight} idText={idText}/>
                    </div>
                </div>
            </div>
        </div>
    )
}