import React from "react";
import "./Price Context.css"
import {useLanguageContext} from "../../../../../Context/LanguageContext";

export const PricesContext = props =>{

    const {dataPrices} = props

    const {actualLanguage} = useLanguageContext()

    return(
        <div className={"box-price-context"}>
            {dataPrices.map(dataPrice => {
                return(
                    <div className={"price-context"}>
                        <div className={"text-price"}>{dataPrice[`textPrice_${actualLanguage}`]}</div>
                        {(dataPrice.idText === "firstAppointment") ? <div className={"price"}>{dataPrice[`priceText_${actualLanguage}`]}</div> : <div className={"price"}>{dataPrice.Price} &euro; </div>}
                    </div>
                )
            })}
            {(actualLanguage === 'cat') ? <p>*Una sessió equival a una hora</p> : <p>*Una sesión equivale a una hora</p>}
        </div>
    )
}