import React from "react";
import "./Logo.css"
import logo from "../../../Img/LOGO_FINAL.png"

export const Logo = props => {
    return(
        <div>
            <img className={"logo"} src={logo} alt="Logo"/>
        </div>
    )
}