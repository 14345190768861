import React, {useState, useContext, createContext, useEffect} from "react"

import {titlesDictionary} from "../dictionaries/generalTitles/titles";

const TitlesContext = createContext();

export const TitlesContextProvider = ({children}) => {

    const [titles, setTitles] = useState(undefined);

    useEffect(()=> {
        setTitles(titlesDictionary)
    }, []);

    if(titles) {
        return(
            <TitlesContext.Provider value={{titles, setTitles}}>
                {children}
            </TitlesContext.Provider>
        )
    }else{
        return null;
    }

}

export const useTitlesContext = () => useContext(TitlesContext);