import React, {useEffect, useState} from "react";

import {FooterBottomContext} from "./FooterBottomContext/FooterBottomContext";
import {textFooterBottomDictionary} from "../../../../dictionaries/footer/textFooterBootom";

export const FooterBottom = props => {

    const [dataTextFooterBottom, setDataTextFooterBottom] = useState(undefined)

    useEffect(()=> {
        setDataTextFooterBottom(textFooterBottomDictionary);
    }, []);

    return(
        <div>
            {dataTextFooterBottom && <FooterBottomContext dataTextFooterBottom={dataTextFooterBottom}/>}
        </div>
    )
}