import React from 'react'
import "./Languages.css"
import {Language} from "./ActualLanguage/ActualLanguage"

export const Languages = props => {

    const {dataLanguages} = props

    const numberOfLanguages = dataLanguages.length - 1 ;

    return(
        <div className="languages">
            {dataLanguages.map((data, index) =>{
                return(
                   <div className={"box-language"}>
                        <Language Language={data}/>
                        {(index !== numberOfLanguages) && <div className={"separator-language"}>{""}</div> }
                   </div>
                )
            })}
        </div>
    )
}