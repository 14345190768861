import React from "react";
import "./FooterTopContext.css"
import {Logo} from "../../../Logo/Logo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck, faBus, faSubway, faStreetView, faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {useLanguageContext} from "../../../../../Context/LanguageContext";
import {useTitlesContext} from "../../../../../Context/TitlesContext";
import {findTitle} from "../../../../../Functions/FindTitle";

export const FooterTopContext = props => {

    const {dataTextFooter} = props

    const {actualLanguage} = useLanguageContext()
    const {titles} = useTitlesContext()

    const ifaCheck = <FontAwesomeIcon className="icon icon-size-check" icon={faCheck}/>
    const ifaBus = <FontAwesomeIcon className="icon icon-size-bus" icon={faBus}/>
    const ifaSubway = <FontAwesomeIcon className="icon icon-size-subway" icon={faSubway}/>
    const ifaStreetView = <FontAwesomeIcon className="icon icon-size-streetView" icon={faStreetView}/>
    const ifaPhone = <FontAwesomeIcon className="icon icon-size-phone" icon={faPhone}/>
    const ifaEnvelope = <FontAwesomeIcon className="icon icon-size-envelope" icon={faEnvelope}/>

    const location = [
        {icon: ifaStreetView, text: "Av. Cornellà nº2 Entl. 1, Esplugues de Llobregat"},
        {icon: ifaEnvelope, text: "psicologia@sompsic.com"},
        {icon: ifaPhone, text: "667558101"},
        {icon: ifaBus, text: "Bus: 67, 68, 78, EP1, EP2, L50, L51, L57, L62."},
        {icon: ifaSubway, text: "Tram: T1, T2, T3"},
    ]

    return(
        <div className={"footer"}>
            <div className={"footer-context"}>
                <div className={"footer-flex-left"}>
                    <Logo/>

                </div>
                <div className={"footer-flex-center"}>
                    <h4>{findTitle(titles, "treatments", actualLanguage)}</h4>
                    <div className={"line-title-h4"}>{""}</div>
                    <div className={"context"}>
                        {dataTextFooter.map(textFooter => {
                            return(
                                <div className={"footer-text"}>
                                    <div>{ifaCheck}</div>
                                    <p>{textFooter[`textContext_${actualLanguage}`]}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={"footer-flex-right"}>
                    <h4>{findTitle(titles, "howToArrive", actualLanguage)}</h4>
                    <div className={"line-title-h4"}>{""}</div>
                    {location.map(dataLocation => {
                        return(
                            <div className={"context"}>
                                <div className={"box-context"}>
                                    <div>{dataLocation.icon}</div>
                                    <p>{dataLocation.text}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}