import React, {useEffect, useState} from "react"
import "./Contact.css"
import {Location} from "../../General/Location/Location";
import {HeaderContact} from "./HeaderContact/HeaderContact";
import {contactInformationDictionary} from "../../../dictionaries/contactInformation/contactInformation";

export const Contact = props =>{

    const [contactInformation, setContactInformation] = useState(undefined);

    useEffect(() =>{
        setContactInformation(contactInformationDictionary);
    }, []);

    return(
        <div className={"contact-view container"}>
            <HeaderContact contactInformation={contactInformation}/>
            <Location/>
        </div>
    )
}