import React, {useState} from "react";
import "./HeaderPhotoOptions.css"
import {Link} from "react-router-dom";
import {useLanguageContext} from "../../../../Context/LanguageContext";

export const HeaderPhotoOptions = props => {

    const {dataHeaderContext, type} = props

    const [active, setActive] = useState(false)

    const {actualLanguage} = useLanguageContext()

    const headerActiveEnter = () => setActive(true);
    const headerActiveLeave = () => setActive(false);

    return(
        <div className={"box-link-list-text"} onMouseEnter={ () => headerActiveEnter()} onMouseLeave={ () => headerActiveLeave()}>
            <div className={ (active === true) ? "line-header-photo active" : "line-header-photo" }>{""}</div>
            <Link to={`/${type}/${dataHeaderContext.idText}`} className={"link-list-text"}>
                <div className={"text-header-photo"}>{dataHeaderContext[`name_${actualLanguage}`]}</div>
            </Link>
            <div className={ (active === true) ? "line-header-photo active" : "line-header-photo" }>{""}</div>
        </div>
    )
}