import React from "react";
import "./DrawerMenuContext.css"
import {Link} from "react-router-dom";
import {useLanguageContext} from "../../../../../../../Context/LanguageContext";

export const DrawerMenuContext = props =>{

    const{dataBottomHeaderRight, allDataDropDown, closeDrawerMenu} = props

    const {actualLanguage} = useLanguageContext()

    return(
        <div className={"box-drawer-menu"}>
            {dataBottomHeaderRight.map(menuContext => {
                return(
                    <div className={"drawer-menu-title"}>
                    {Object.keys(allDataDropDown).map(key => {
                        return(
                            <div>
                                {(menuContext.idText) === key && ((menuContext.idText === "adults") || (menuContext.idText === "teenagers") || (menuContext.idText === "children")) &&
                                <Link to={`/${menuContext.idText}/all`} className={"drawer-menu-link"} onClick={() => closeDrawerMenu()}>
                                    <p className="drawer-menu-title-text">{menuContext[`contextMenu_${actualLanguage}`].toUpperCase()}</p>
                                </Link>
                                }
                                    {(menuContext.idText) === key && (allDataDropDown[key] !== undefined) &&
                                    <ul className="drawer-menu-list" >
                                        {allDataDropDown[key].map((context) => {
                                            return(
                                                <div>
                                                    <Link to={`/${menuContext.idText}/${context.idText}`} className={"drawer-menu-link"} onClick={() => closeDrawerMenu()}>
                                                        <li className={"drawer-menu-list-text"}>{context[`name_${actualLanguage}`]}</li>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                    }
                                { (menuContext.idText) === key && ((menuContext.idText === "contact") || (menuContext.idText === "aboutUs")) &&
                                <Link to={`/${menuContext.idText}`} className={"drawer-menu-link"} onClick={() => closeDrawerMenu()}>
                                    <p className="drawer-menu-title-text">{menuContext[`contextMenu_${actualLanguage}`].toUpperCase()}</p>
                                </Link>
                                }

                            </div>
                        )
                    })}
                    </div>
                    )
                })
            }
        </div>
    )
}