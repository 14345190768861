import React from "react";
import "./CenterContext.css"
import {useLanguageContext} from "../../../../Context/LanguageContext";
import {useTitlesContext} from "../../../../Context/TitlesContext";
import {findTitle} from "../../../../Functions/FindTitle";

export const CenterContext = props => {

    const {dataCenter, idText} = props

    const {actualLanguage} = useLanguageContext()
    const {titles} = useTitlesContext()

    return(
        <div className={"box-center"}>
            <div className={"box-title-center"}>
                <p className={"title-center-text"}>{findTitle(titles, "whatWeDeal", actualLanguage).toUpperCase()}</p>
                <div className={"line-title-h4"}>{""}</div>
            </div>
            <div className={"box-center-context"}>
            {Object.keys(dataCenter).map(key =>{
                return(
                    <div>
                       {(idText === key) &&
                        dataCenter[key].map(data => {
                            return(
                                <div className={"box-center-text"}>
                                    <div><p className={"center-text"}>{data[`text_${actualLanguage}`]}</p></div>
                                </div>
                            )
                        })
                        }
                    </div>
                )
            })}
            </div>
        </div>
    )
}