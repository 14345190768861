import React, {useState, useEffect} from "react";
import {HeaderDropDown} from "./HeaderDropDown/HeaderDropDown"
import {Link} from 'react-router-dom'
import {Logo} from "../../Logo/Logo";
import {DrawerMenu} from "./DrawerMenu/DrawerMenu";
import {headerBottomTitleAdultsDictionary} from "../../../../dictionaries/header/headerBottomTitleAdults";
import {headerBottomTitleTeenagersDictionary} from "../../../../dictionaries/header/headerBottomTitleTeenagers";
import {headerBottomTitleChildrenDictionary} from "../../../../dictionaries/header/headerBottomTitleChildren";

export const BottomHeader = props => {

    const {dataBottomHeaderRight} = props

    const [dataDropDownAdults, setDataDropDownAdults] = useState(undefined);
    const [dataDropDownTeenagers, setDataDropDownTeenagers] = useState(undefined);
    const [dataDropDownChildren, setDataDropDownChildren] = useState(undefined);

    const allDataDropDown = {adults:dataDropDownAdults, teenagers:dataDropDownTeenagers, children: dataDropDownChildren, contact:undefined, aboutUs:undefined};

    useEffect(()=> {
        setDataDropDownAdults(headerBottomTitleAdultsDictionary)
        setDataDropDownTeenagers(headerBottomTitleTeenagersDictionary)
        setDataDropDownChildren(headerBottomTitleChildrenDictionary);
    }, []);

    return (
        <div className={"border-bottom-header"}>
            <div className="bottom-header">
                <div className="bottom-header-context">
                    <div className="bottom-header-left">
                        <Link to={"/"}><Logo/></Link>
                    </div>
                    <div className="bottom-header-right">
                        {dataDropDownAdults && dataDropDownTeenagers && dataDropDownChildren &&
                            <div className={"drop-down-menu"}>
                                {dataBottomHeaderRight.map(menuContext => {
                                return(
                                    <div className={"bottom-button-header"}>
                                        {Object.keys(allDataDropDown).map(key => {
                                            return(
                                                <div>
                                                    {(menuContext.idText) === key &&
                                                        <HeaderDropDown dataContextDropDown={allDataDropDown[key]} menuContext={menuContext}/>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                                })}
                            </div>
                        }
                        {dataDropDownAdults && dataDropDownTeenagers && dataDropDownChildren &&
                            <div className={"drawer-menu"}>
                                <DrawerMenu dataBottomHeaderRight={dataBottomHeaderRight} allDataDropDown={allDataDropDown}/>
                            </div>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

