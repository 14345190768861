import React from "react";
import "./HeaderPhoto.css"
import {HeaderPhotoOptions} from "./HeaderPhotoOptions/HeaderPhotoOptions";

export const HeaderPhoto = props =>{

    const {headerAdults, type} = props


    return(
        <div className={"box-header"}>
            <div className={"header-flex"}>
                {headerAdults.map(data =>{
                    return(
                        <HeaderPhotoOptions dataHeaderContext={data} type={type}/>
                    )
                })}
            </div>
        </div>
    )
}

