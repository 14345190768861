import React from "react";
import "./Location.css"
import 'leaflet/dist/leaflet.css';
import { Map, TileLayer, Marker, Popup } from "react-leaflet"
import L from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBus, faSubway} from '@fortawesome/free-solid-svg-icons'
import {useLanguageContext} from "../../../Context/LanguageContext";
import {useTitlesContext} from "../../../Context/TitlesContext";
import {findTitle} from "../../../Functions/FindTitle";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


export const Location = () => {

    const {actualLanguage} = useLanguageContext();
    const {titles} = useTitlesContext();

    const ifaBus = <FontAwesomeIcon className="icon icon-size-bus" icon={faBus} />
    const ifaSubway = <FontAwesomeIcon className="icon icon-size-subway" icon={faSubway} />

    return(
        <div className={"container"}>
            <h3 className={"treatments-title"}>{findTitle(titles, "whereWeAre", actualLanguage).toUpperCase()}</h3>
            <div className={"line-title-h3"}>{""}</div>
            <div className={"maps-container"}>
                <div className={"map-context"}>
                    <Map className={"map"} center={[41.37705, 2.08846]} zoom={17}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Marker position={[41.37705, 2.08846]}>
                            <Popup keepInView={true}>
                                SomPsic Psicologia.
                            </Popup>
                        </Marker>
                    </Map>
                </div>
                <div className={"box-map-info"}>
                    <div className={"sub-box-map-info"}>
                        <h4>{findTitle(titles, "howToArrive", actualLanguage)}</h4>
                        <div className={"line-title-h4"}>{""}</div>
                        <p className={"text-map-info"}>Av. Cornellà nº2 Entl. 1, Esplugues de Llobregat</p>
                        <div className={"box-transports"}>
                            <div className={"transport"}>
                                <div>{ifaBus}</div>
                                <p className={"text-map-info"}>Bus: 67, 68, 78, EP1, EP2, L50, L51, L57, L62.</p>
                            </div>
                            <div className={"transport"}>
                                <div className={"icon-transport"}>{ifaSubway}</div>
                                <p className={"text-map-info"}>Tram: T1, T2, T3</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}