export const headerBottomTitlesDictionary = [
    {
        "id": 1,
        "idText": "adults",
        "contextMenu_cat": "Adults",
        "contextMenu_es": "Adultos"
    },
    {
        "id": 2,
        "idText": "teenagers",
        "contextMenu_cat": "Adolescents",
        "contextMenu_es": "Adolescentes"
    },
    {
        "id": 3,
        "idText": "children",
        "contextMenu_cat": "Infantil",
        "contextMenu_es": "Infantil"
    },
    {
        "id": 4,
        "idText": "aboutUs",
        "contextMenu_cat": "Sobre Nosaltres",
        "contextMenu_es": "Sobre Nosotros"
    },
    {
        "id": 5,
        "idText": "contact",
        "contextMenu_cat": "Contacte",
        "contextMenu_es": "Contacto"
    }
]