export const cardFlipTreatmentsDictionary = [
    {
        "id": 1,
        "nameFront_cat": "Ansietat",
        "nameFront_es": "Ansiedad",
        "contextBack_cat": "Sentiment d'inquietud, nerviosisme, preocupació, temor, pànic... Davant un acte a punt d'ucòrrer.",
        "contextBack_es": "Sentimiento de inquietud, nerviosismo, preocupación, temor, pànico... Ante un acto a punto de ocurrir.",
        "imgUrl": "https://images.unsplash.com/photo-1583598418288-843337a6df41?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1052&q=80"
    },
    {
        "id": 2,
        "nameFront_cat": "Depressions",
        "nameFront_es": "Depresiones",
        "contextBack_cat": "Trastorn de l'estat anímic en el qual els sentiments de puèrdua, tristesa, frustració, enuig... interfereixen en l'activitat quotidiana.",
        "contextBack_es": "Trastorno del estado anímico en el que los sentimientos de pérdida, tristeza, frustración, enojo... interfieren en la actividad cotidiana.",
        "imgUrl": "https://cdn.pixabay.com/photo/2018/05/22/14/00/girl-3421489_960_720.jpg"
    },
    {
        "id": 3,
        "nameFront_cat": "Fòbies",
        "nameFront_es": "Fobias",
        "contextBack_cat": "Temor intens o irracional cap a alguna cosa o objecte.",
        "contextBack_es": "Temor intenso o irracional hacia algo u objeto.",
        "imgUrl": "https://images.unsplash.com/photo-1528817164944-2cf16aefdc8d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=797&q=80"
    },
    {
        "id": 4,
        "nameFront_cat": "Trastorns alimentaris",
        "nameFront_es": "Trastornos alimentarios",
        "contextBack_cat": "Problemes sobre la forma de pensar en el menjar i la conducta alimentaria.",
        "contextBack_es": "Problemas sobre la forma de pensar en la comida y la conducta alimentaria.",
        "imgUrl": "https://images.unsplash.com/photo-1579119134518-27693cf605b1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=869&q=80"
    },
    {
        "id": 5,
        "nameFront_cat": "Reeducacions",
        "nameFront_es": "Reeducaciones",
        "contextBack_cat": "Teràpia d'aprenentatge personalitzada, adaptada a les necessitats que cada nen o adolescent presenta en un moment de la seva etapa escolar.",
        "contextBack_es": "Terapia de aprendizaje personalizada, adaptada a las necesidades que cada niu00f1o o adolescente presenta en un momento de su etapa escolar.",
        "imgUrl": "https://images.unsplash.com/photo-1564609116494-380be7238d7d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
    },
    {
        "id": 6,
        "nameFront_cat": "Problemes de parella",
        "nameFront_es": "Problemas de pareja",
        "contextBack_cat": "Problemes de comunicació, infidelitats, esdeveniments traumàtics, problemes de convivència...",
        "contextBack_es": "Problemas de comunicación, infidelidades, eventos traumáticos, problemas de convivencia...",
        "imgUrl": "https://images.unsplash.com/photo-1474552226712-ac0f0961a954?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjI5MzI0fQ&auto=format&fit=crop&w=1051&q=80"
    },
    {
        "id": 7,
        "nameFront_cat": "Problemes familiars",
        "nameFront_es": "Problemas familiares",
        "contextBack_cat": "Discussions i enfrontaments, conflictes entre pares i fills, conflictes entre germans, problemes de comunicació...",
        "contextBack_es": "Discusiones y enfrentamientos, conflictos entre padres e hijos, conflictos entre hermanos, problemas de comunicación...",
        "imgUrl": "https://images.unsplash.com/photo-1526301352057-62f44bf4e826?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1052&q=80"
    },
    {
        "id": 8,
        "nameFront_cat": "Trastorns conductuals",
        "nameFront_es": "Trastornos conductuales",
        "contextBack_cat": "Caracteritzats per comportaments antisocials que no respecten els drets dels altres i les regles socials apropiades per a la edat.",
        "contextBack_es": "Caracterizados por comportamientos antisociales que no respetan los derechos de los demás y las reglas sociales apropiadas para la edad.",
        "imgUrl": "https://images.unsplash.com/photo-1529507672001-41b8facb0e2a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80"
    },
    {
        "id": 9,
        "nameFront_cat": "TDAH",
        "nameFront_es": "TDAH",
        "contextBack_cat": "Problemes atencionals, d'impulsivitat i hiperactivitat.",
        "contextBack_es": "Problemas atencionales, de impulsividad e hiperactividad.",
        "imgUrl": "https://images.unsplash.com/photo-1453342664588-b702c83fc822?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1040&q=80"
    },
    {
        "id": 10,
        "nameFront_cat": "Habilitats socials i autoestima",
        "nameFront_es": "Habilidades sociales y autoestima",
        "contextBack_cat": "Problemes per a relacionar-se amb els altres, problemes de baixa autoestima, inseguretats, introversió...",
        "contextBack_es": "Problemas para relacionarse con los demás, problemas de baja autoestima, inseguridades, introversión...",
        "imgUrl": "https://images.unsplash.com/photo-1444210971048-6130cf0c46cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1052&q=80"
    }
]