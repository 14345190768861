import React, {useEffect, useState} from "react";
import {TopHeader} from "./TopHeader/TopHeader.js";
import "./TopHeader/TopHeader.css"
import {BottomHeader} from "./BottomHeader/BottomHeader.js";
import "./BottomHeader/BottomHeader.css"
import "./Header.css"
import {headerBottomTitlesDictionary} from "../../../dictionaries/header/headerBottomTitles";

export const Header = props => {

    const [dataBottomHeaderRight, SetDataBottomHeaderRight] = useState(undefined);

    useEffect(()=> {
          SetDataBottomHeaderRight(headerBottomTitlesDictionary);
    }, []);

    return(
        <div className={"header"} id={"header"}>
            <TopHeader/>
            {dataBottomHeaderRight && <BottomHeader dataBottomHeaderRight={dataBottomHeaderRight}/>}
        </div>
    )
}
