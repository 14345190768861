import React, {useState} from "react";
import "./DrawerMenu.css"
import {DrawerMenuCall} from "./DrawerMenuCall/DrawerMenuCall";

export const DrawerMenu = props =>{

    const {dataBottomHeaderRight, allDataDropDown} = props

    const [dropDownDrawerMenu, setDropDownDrawerMenu] = useState(false)

    const closeDrawerMenu = () => setDropDownDrawerMenu(false);

    return(
        <div>
        <div className={"lines-drawer"} onClick={() => setDropDownDrawerMenu(!dropDownDrawerMenu)}>
            <div>{""}</div>
            <div>{""}</div>
            <div>{""}</div>
        </div>
            {dropDownDrawerMenu=== true && <DrawerMenuCall dropDownDrawerMenu={dropDownDrawerMenu}
                                                           closeDrawerMenu={closeDrawerMenu}
                                                           dataBottomHeaderRight={dataBottomHeaderRight}
                                                           allDataDropDown={allDataDropDown}/>}
        </div>
    )
}