export const headerBottomTitleChildrenDictionary = [
    {
        "id": 3,
        "idText": "childrenIndividual",
        "name_cat": "Psicoter\u00e0pia individual",
        "name_es": "Psicoterapia individual",
        "gender_id": 3,
        "gender": "children"
    },
    {
        "id": 3,
        "idText": "childrenFamiliar",
        "name_cat": "Psicoter\u00e0pia familiar",
        "name_es": "Psicoterapia familiar",
        "gender_id": 3,
        "gender": "children"
    },
    {
        "id": 3,
        "idText": "childrenSocial",
        "name_cat": "Habilitats socials",
        "name_es": "Habilidades sociales",
        "gender_id": 3,
        "gender": "children"
    },
    {
        "id": 3,
        "idText": "childrenReeducation",
        "name_cat": "Reeducacions",
        "name_es": "Reeducaciones",
        "gender_id": 3,
        "gender": "children"
    }
]