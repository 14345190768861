import React, {useEffect, useState} from "react"
import {findTitle} from "../../../../Functions/FindTitle";
import {PricesContext} from "./Price Context/Price Context";
import {useLanguageContext} from "../../../../Context/LanguageContext";
import {useTitlesContext} from "../../../../Context/TitlesContext";
import {pricesDictionary} from "../../../../dictionaries/prices/prices";

export const Prices = () =>{

    const [dataPrices, setDataPrices] = useState(undefined);

    const {actualLanguage} = useLanguageContext(undefined)
    const {titles} = useTitlesContext()

    useEffect(() =>{
        setDataPrices(pricesDictionary);
    }, []);

    return(
        <div>
            <div>
                <h3 className={"questions-title"}>{findTitle(titles, "prices", actualLanguage).toUpperCase()}</h3>
                <div className={"line-title-h3"}>{""}</div>
                {dataPrices &&
                    <PricesContext dataPrices={dataPrices}/>
                }
            </div>
        </div>
    )
}