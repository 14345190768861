export const therapyAdultsCenterDictionary = [
    {
        "id": 1,
        "text_cat": "Depressi\u00f3.",
        "text_es": "Depresi\u00f3n.",
        "genderTherapy": 1,
        "location": 1,
        "type": "individual",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 1,
        "text_cat": "Ansietat.",
        "text_es": "Ansiedad.",
        "genderTherapy": 1,
        "location": 1,
        "type": "individual",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 1,
        "text_cat": "Problemes emocionals.",
        "text_es": "Problemas emocionales.",
        "genderTherapy": 1,
        "location": 1,
        "type": "individual",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 1,
        "text_cat": "Problemes en les relacions socials.",
        "text_es": "Problemas en las relaciones sociales.",
        "genderTherapy": 1,
        "location": 1,
        "type": "individual",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 1,
        "text_cat": "F\u00f2bies.",
        "text_es": "Fobias.",
        "genderTherapy": 1,
        "location": 1,
        "type": "individual",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 1,
        "text_cat": "Obsessions.",
        "text_es": "Obsesiones.",
        "genderTherapy": 1,
        "location": 1,
        "type": "individual",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 1,
        "text_cat": "Dols.",
        "text_es": "Duelos.",
        "genderTherapy": 1,
        "location": 1,
        "type": "individual",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 2,
        "text_cat": "Problemes de comunicaci\u00f3.",
        "text_es": "Problemas de comunicaci\u00f3n.",
        "genderTherapy": 1,
        "location": 1,
        "type": "couple",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 2,
        "text_cat": "Problemes de conviv\u00e8ncia.",
        "text_es": "Problemas de convivencia.",
        "genderTherapy": 1,
        "location": 1,
        "type": "couple",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 2,
        "text_cat": "Infidelitats.",
        "text_es": "Infidelidades.",
        "genderTherapy": 1,
        "location": 1,
        "type": "couple",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 2,
        "text_cat": "Problemes sexuals.",
        "text_es": "Problemas sexuales.",
        "genderTherapy": 1,
        "location": 1,
        "type": "couple",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 2,
        "text_cat": "Esdeveniments traum\u00e0tics.",
        "text_es": "Eventos traum\u00e1ticos.",
        "genderTherapy": 1,
        "location": 1,
        "type": "couple",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 2,
        "text_cat": "Problemes amb la fam\u00edlia de la parella.",
        "text_es": "Problemas con la familia de la pareja.",
        "genderTherapy": 1,
        "location": 1,
        "type": "couple",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 3,
        "text_cat": "Relacions familiars conflictives.",
        "text_es": "Relaciones familiares conflictivas.",
        "genderTherapy": 1,
        "location": 1,
        "type": "familiar",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 3,
        "text_cat": "Viol\u00e8ncia dom\u00e8stica.",
        "text_es": "Violencia dom\u00e9stica.",
        "genderTherapy": 1,
        "location": 1,
        "type": "familiar",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 3,
        "text_cat": "Problemes en les relacions amb els fills.",
        "text_es": "Problemas en las relaciones con los hijos.",
        "genderTherapy": 1,
        "location": 1,
        "type": "familiar",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 3,
        "text_cat": "Separacions.",
        "text_es": "Separaciones",
        "genderTherapy": 1,
        "location": 1,
        "type": "familiar",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 3,
        "text_cat": "Problemes de comunicaci\u00f3.",
        "text_es": "Problemas de comunicaci\u00f3n.",
        "genderTherapy": 1,
        "location": 1,
        "type": "familiar",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 3,
        "text_cat": "Esdeveniments estressants.",
        "text_es": "Eventos estresantes.",
        "genderTherapy": 1,
        "location": 1,
        "type": "familiar",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 4,
        "text_cat": "Depressi\u00f3.",
        "text_es": "Depresi\u00f3n.",
        "genderTherapy": 1,
        "location": 1,
        "type": "online",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 4,
        "text_cat": "Ansietat.",
        "text_es": "Ansiedad.",
        "genderTherapy": 1,
        "location": 1,
        "type": "online",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 4,
        "text_cat": "Estr\u00e9s.",
        "text_es": "Estr\u00e9s.",
        "genderTherapy": 1,
        "location": 1,
        "type": "online",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 4,
        "text_cat": "Problemes emocionals.",
        "text_es": "Problemas Emocionales.",
        "genderTherapy": 1,
        "location": 1,
        "type": "online",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 4,
        "text_cat": "F\u00f2bies.",
        "text_es": "Fobias.",
        "genderTherapy": 1,
        "location": 1,
        "type": "online",
        "gender": "adults",
        "Location": "Center"
    },
    {
        "id": 4,
        "text_cat": "etc...",
        "text_es": "etc...",
        "genderTherapy": 1,
        "location": 1,
        "type": "online",
        "gender": "adults",
        "Location": "Center"
    }
]