export const headerBottomTitleTeenagersDictionary = [
    {
        "id": 2,
        "idText": "teenagersIndividual",
        "name_cat": "Psicoter\u00e0pia individual",
        "name_es": "Psicoterapia individual",
        "gender_id": 2,
        "gender": "teenagers"
    },
    {
        "id": 2,
        "idText": "teenagersSocial",
        "name_cat": "Habilitats socials",
        "name_es": "Habilidades sociales",
        "gender_id": 2,
        "gender": "teenagers"
    },
    {
        "id": 2,
        "idText": "teenagersFamiliar",
        "name_cat": "Psicoter\u00e0pia familiar",
        "name_es": "Psicoterapia familiar",
        "gender_id": 2,
        "gender": "teenagers"
    },
    {
        "id": 2,
        "idText": "teenagersReeducation",
        "name_cat": "Reeducacions",
        "name_es": "Reeducaciones",
        "gender_id": 2,
        "gender": "teenagers"
    },
    {
        "id": 2,
        "idText": "teenagersOnline",
        "name_cat": "Psicoter\u00e0pia online",
        "name_es": "Psicoterapia online",
        "gender_id": 2,
        "gender": "teenagers"
    }
]