export const textFooterTopDictionary = [
    {
        "id": 1,
        "textContext_cat": "Psicoter\u00e0pia adult.",
        "textContext_es": "Psicoterapia adulto."
    },
    {
        "id": 2,
        "textContext_cat": "Psicoter\u00e0pia adolescent.",
        "textContext_es": "Psicoterapia adolescente."
    },
    {
        "id": 3,
        "textContext_cat": "Psicoter\u00e0pia infantil.",
        "textContext_es": "Psicoterapia infantil."
    },
    {
        "id": 4,
        "textContext_cat": "Reeducacions.",
        "textContext_es": "Reeducaciones."
    },
    {
        "id": 5,
        "textContext_cat": "Grups.",
        "textContext_es": "Grupos."
    }
]