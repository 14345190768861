import React, {useEffect, useState} from "react";
import "./TeenagersView.css"
import {ExplicationTherapy} from "../../General/ExplicationTherapy/ExplicationTherapy";
import {HeaderPhoto} from "../../General/HeaderPhoto/HeaderPhoto";
import {useLanguageContext} from "../../../Context/LanguageContext";
import {useTitlesContext} from "../../../Context/TitlesContext";
import {findTitle} from "../../../Functions/FindTitle";
import {
    explicationTherapyTeenagersDictionary
} from "../../../dictionaries/therapyDetails/therapyTeenagers/explicationTherapyTeenagers";
import {
    explicationTherapyHeaderPhotoTeenagersDictionary
} from "../../../dictionaries/therapyDetails/therapyTeenagers/explicationTherapyHeaderPhotoTeenagers";
import {
    therapyTeenagersRightDictionary
} from "../../../dictionaries/therapyDetails/therapyTeenagers/therapyTeenagersRight";
import {
    therapyTeenagersCenterDictionary
} from "../../../dictionaries/therapyDetails/therapyTeenagers/therapyTeenagersCenter";
import {headerBottomTitleTeenagersDictionary} from "../../../dictionaries/header/headerBottomTitleTeenagers";

export const TeenagersView = props => {

    const {match} = props

    const urlParam = match.params["open"];

    const {actualLanguage} = useLanguageContext()
    const {titles} = useTitlesContext()

    const [dataExplicationTherapyTeenagers, setDataExplicationTherapyTeenagers] = useState(undefined);
    const [individualRight, setIndividualRight] = useState(undefined);
    const [socialRight, setSocialRight] = useState(undefined);
    const [familiarRight, setFamiliarRight] = useState(undefined);
    const [reeducationRight, setReeducationRight] = useState(undefined);
    const [onlineRight, setOnlineRight] = useState(undefined);
    const [groupRight, setGroupRight] = useState(undefined);
    const [individualCenter, setIndividualCenter] = useState(undefined);
    const [socialCenter, setSocialCenter] = useState(undefined);
    const [familiarCenter, setFamiliarCenter] = useState(undefined);
    const [reeducationCenter, setReeducationCenter] = useState(undefined);
    const [onlineCenter, setOnlineCenter] = useState(undefined);
    const [groupCenter, setGroupCenter] = useState(undefined);
    const [headerTeenagers, setHeaderTeenagers] = useState(undefined);
    const [headerTeenagersPhoto, setHeaderTeenagersPhoto] = useState(undefined);

    const rightInfoTeenagers = {"teenagersIndividual": individualRight, "teenagersSocial": socialRight, "teenagersFamiliar": familiarRight, "teenagersReeducation": reeducationRight,
                                "teenagersOnline": onlineRight, "teenagersGroup": groupRight};
    const centerInfoTeenagers = {"teenagersIndividual": individualCenter, "teenagersSocial": socialCenter, "teenagersFamiliar": familiarCenter, "teenagersReeducation": reeducationCenter,
        "teenagersOnline": onlineCenter, "teenagersGroup": groupCenter};

    useEffect(() =>{
        setHeaderTeenagers(headerBottomTitleTeenagersDictionary);
        setDataExplicationTherapyTeenagers(explicationTherapyTeenagersDictionary);
        setHeaderTeenagersPhoto(explicationTherapyHeaderPhotoTeenagersDictionary);

        setIndividualRight(therapyTeenagersRightDictionary.filter(data => data.type === "individual"));
        setSocialRight(therapyTeenagersRightDictionary.filter(data => data.type === "social"));
        setFamiliarRight(therapyTeenagersRightDictionary.filter(data => data.type === "familiar"));
        setReeducationRight(therapyTeenagersRightDictionary.filter(data => data.type === "reeducation"));
        setOnlineRight(therapyTeenagersRightDictionary.filter(data => data.type === "online"));
        setGroupRight(therapyTeenagersRightDictionary.filter(data => data.type === "group"));

        setIndividualCenter(therapyTeenagersCenterDictionary.filter(data => data.type === "individual"));
        setSocialCenter(therapyTeenagersCenterDictionary.filter(data => data.type === "social"));
        setFamiliarCenter(therapyTeenagersCenterDictionary.filter(data => data.type === "familiar"));
        setReeducationCenter(therapyTeenagersCenterDictionary.filter(data => data.type === "reeducation"));
        setOnlineCenter(therapyTeenagersCenterDictionary.filter(data => data.type === "online"));
        setGroupCenter(therapyTeenagersCenterDictionary.filter(data => data.type === "group"));

    }, []);


    return(
        <div className={"teenagers-view container"}>
            <h3 className={"title-teenagers-view"}>{findTitle(titles, "teenagersTherapy", actualLanguage).toUpperCase()}</h3>
            <div className={"line-title-h3"}>{""}</div>
            <div className={"box-header-teenagers"}>
                {headerTeenagers && <HeaderPhoto  headerAdults={headerTeenagers} type={"teenagers"}/>}
                {headerTeenagersPhoto && <img src={headerTeenagersPhoto[0].img_url} alt="adults"/> }
            </div>
            {dataExplicationTherapyTeenagers && individualRight && socialRight && familiarRight && reeducationRight && onlineRight && groupRight &&
            individualCenter && socialCenter && familiarCenter && reeducationCenter && onlineCenter && groupCenter &&
                dataExplicationTherapyTeenagers.map(dataTherapy =>{
                    return(
                        <div id={(dataTherapy.idText === urlParam) ? "explicationTherapyActive" : ""}>
                             <ExplicationTherapy img={dataTherapy.img_url} title={dataTherapy[`title_${actualLanguage}`]}
                                                 dataCenter={centerInfoTeenagers}
                                                 dataRight={rightInfoTeenagers}
                                                 idText={dataTherapy.idText}
                                                 urlParam={urlParam}
                             />
                        </div>
                    )
                })
            }
        </div>
    )
}