import {useEffect} from 'react';

export const ScrollToUp = props => {

    const {location} = props

    const  pathname  = location.pathname;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}