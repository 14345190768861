import React from "react"
import "./ContextFrequentQuestions.css"
import {Question} from "./Question/Question.js"
import {useLanguageContext} from "../../../../../Context/LanguageContext";
import {useTitlesContext} from "../../../../../Context/TitlesContext";
import {findTitle} from "../../../../../Functions/FindTitle";


export const ContextFrequentQuestions = props => {

    const {dataFrequentQuestions} = props

    const {actualLanguage} = useLanguageContext()
    const {titles} = useTitlesContext()

    return(
        <div>
            <h3 className={"questions-title"}>{findTitle(titles, "FAQS", actualLanguage).toUpperCase()}</h3>
            <div className={"line-title-h3"}>{""}</div>
            <div className={"box-questions-context"}>
            {dataFrequentQuestions.map( (data, index) => {
                return(
                       <Question data={data} index={index}/>
                )
            })}
            </div>
        </div>
    )
}