export const aboutUSInformationDictionary = [
    {
        "id": 1,
        "helpParagraphOne_cat": "A SomPsic treballem per ajudar-te a aconseguir els teus objectius i aix\u00ed arribar a un nivell satisfactori de benestar amb un mateix. ",
        "helpParagraphOne_es": "En SomPsic trabajamos para ayudarte a conseguir tus objetivos y as\u00ed alcanzar un nivel satisfactorio de bienestar con uno mismo.",
        "helpParagraphTwo_cat": "Oferim servei d'atenció psicològica tant a nivell individual, de parella, familiar o grupal. Les ter\u00e0pies individuals es poden realitzar al centre o de manera online, si el pacient t\u00e9 algun impediment per realitzar la visita presencial.",
        "helpParagraphTwo_es": "Ofrecemos servicio de atenci\u00f3n psicol\u00f3gica tanto a nivel individual, de pareja, familiar o grupal. Las terapias individuales se pueden realizar en el centro o de manera online, si el paciente tiene alg\u00fan impedimento para realizar la visita presencial.",
        "helpParagraphThree_cat": "Tenim un fort comprom\u00eds amb la salut mental de les persones, pel que el pacient \u00e9s el m\u00e9s important durant tot el proc\u00e9s i la nostra prioritat. ",
        "helpParagraphThree_es": "Tenemos un fuerte compromiso con la salud mental de las personas, por lo que el paciente es lo m\u00e1s importante durante todo el proceso y nuestra prioridad.",
        "helpParagraphFour_cat": "Estem en formaci\u00f3 constant per a poder oferir-te la millor ajuda, estem col\u00b7legiats i comptem amb el M\u00e0ster de Psicologia General Sanit\u00e0ria. ",
        "helpParagraphFour_es": "Estamos en constante formaci\u00f3n para poder ofrecerte la mejor ayuda, estamos colegiados y contamos con el M\u00e1ster de Psicolog\u00eda General Sanitaria.",
        "officeParagraphOne_cat": "El nostre centre est\u00e0 ubicat a Av. de Cornell\u00e0, davant de l\u2019ajuntament d\u2019Esplugues de Llobregat.",
        "officeParagraphOne_es": "Nuestro centro est\u00e1 ubicado en Av. de Cornell\u00e1, frente al ayuntamiento de Esplugues de Llobregat.",
        "officeParagraphTwo_cat": "Es pot arribar a trav\u00e9s de les l\u00ednies del tram T1, T2 i T3 a la parada de Pont d'Esplugues i les l\u00ednies 67, 68, 78, EP1, EP2, L50, L51, L57, L62.",
        "officeParagraphTwo_es": "Se puede llegar a trav\u00e9s del tram T1, T2 y T3 a la parada de Pont d'Esplugues i las l\u00edneas 67, 68, 78, EP1, EP2, L50, L51, L57, L62."
    }
]