export const explicationTherapyAdultsDictionary = [
    {
        "id": 1,
        "idText": "adultsIndividual",
        "img_url": "https://cdn.pixabay.com/photo/2017/12/30/08/49/help-3049553_960_720.jpg",
        "title_cat": "Psicoteràpia individual",
        "title_es": "Psicoterapia individual",
        "gender_id": 1,
        "gender": "adults"
    },
    {
        "id": 1,
        "idText": "adultsCouple",
        "img_url": "https://images.unsplash.com/photo-1514845994104-1be22149278b?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
        "title_cat": "Psicoteràpia de parella",
        "title_es": "Psicoterapia de pareja",
        "gender_id": 1,
        "gender": "adults"
    },
    {
        "id": 1,
        "idText": "adultsFamiliar",
        "img_url": "https://images.unsplash.com/photo-1561525140-c2a4cc68e4bd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
        "title_cat": "Psicoteràpia familiar",
        "title_es": "Psicoterapia familiar",
        "gender_id": 1,
        "gender": "adults"
    },
    {
        "id": 1,
        "idText": "adultsOnline",
        "img_url": "https://images.unsplash.com/photo-1423666639041-f56000c27a9a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1053&q=80",
        "title_cat": "Psicoteràpia online",
        "title_es": "Psicoterapia online",
        "gender_id": 1,
        "gender": "adults"
    }
]