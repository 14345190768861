import React, { useEffect, useState} from "react";
import "./ChildrenView.css"
import {ExplicationTherapy} from "../../General/ExplicationTherapy/ExplicationTherapy";
import {HeaderPhoto} from "../../General/HeaderPhoto/HeaderPhoto";
import {useLanguageContext} from "../../../Context/LanguageContext";
import {useTitlesContext} from "../../../Context/TitlesContext";
import {findTitle} from "../../../Functions/FindTitle";
import {
    explicationTherapyChildrenDictionary
} from "../../../dictionaries/therapyDetails/therapyChildren/explicationTherapyChildren";
import {
    explicationTherapyPhotoChildrenDictionary
} from "../../../dictionaries/therapyDetails/therapyChildren/explicationTherapyPhotoChildren";
import {
    therapyChildrenRightDictionary
} from "../../../dictionaries/therapyDetails/therapyChildren/therapyChildrenRight";
import {
    therapyChildrenCenterDictionary
} from "../../../dictionaries/therapyDetails/therapyChildren/therapyChildrenCenter";
import {headerBottomTitleChildrenDictionary} from "../../../dictionaries/header/headerBottomTitleChildren";

export const ChildrenView = props =>{

    const {match} = props

    const urlParam = match.params["open"];

    const {actualLanguage} = useLanguageContext()
    const {titles} = useTitlesContext()


    const [dataExplicationTherapyChildren, setDataExplicationTherapyChildren] = useState(undefined);
    const [individualRight, setIndividualRight] = useState(undefined);
    const [familiarRight, setFamiliarRight] = useState(undefined);
    const [socialRight, setSocialRight] = useState(undefined);
    const [reeducationRight, setReeducationRight] = useState(undefined);
    const [groupRight, setGroupRight] = useState(undefined);
    const [individualCenter, setIndividualCenter] = useState(undefined);
    const [familiarCenter, setFamiliarCenter] = useState(undefined);
    const [socialCenter, setSocialCenter] = useState(undefined);
    const [reeducationCenter, setReeducationCenter] = useState(undefined);
    const [groupCenter, setGroupCenter] = useState(undefined);
    const [headerChildren, setHeaderChildren] = useState(undefined);
    const [headerChildrenPhoto, setHeaderChildrenPhoto] = useState(undefined);

    const rightInfoChildren = {"childrenIndividual": individualRight, "childrenSocial": socialRight, "childrenFamiliar": familiarRight, "childrenReeducation": reeducationRight,
        "childrenGroup": groupRight};
    const centerInfoChildren = {"childrenIndividual": individualCenter, "childrenSocial":socialCenter, "childrenFamiliar": familiarCenter, "childrenReeducation": reeducationCenter,
        "childrenGroup": groupCenter};


    useEffect(() =>{
        setHeaderChildren(headerBottomTitleChildrenDictionary);
        setDataExplicationTherapyChildren(explicationTherapyChildrenDictionary);
        setHeaderChildrenPhoto(explicationTherapyPhotoChildrenDictionary);

        setIndividualRight(therapyChildrenRightDictionary.filter(data => data.type === "individual"));
        setFamiliarRight(therapyChildrenRightDictionary.filter(data => data.type === "familiar"));
        setSocialRight(therapyChildrenRightDictionary.filter(data => data.type === "social"));
        setReeducationRight(therapyChildrenRightDictionary.filter(data => data.type === "reeducation"));
        setGroupRight(therapyChildrenRightDictionary.filter(data => data.type === "group"));

        setIndividualCenter(therapyChildrenCenterDictionary.filter(data => data.type === "individual"));
        setFamiliarCenter(therapyChildrenCenterDictionary.filter(data => data.type === "familiar"));
        setSocialCenter(therapyChildrenCenterDictionary.filter(data => data.type === "social"));
        setReeducationCenter(therapyChildrenCenterDictionary.filter(data => data.type === "reeducation"));
        setGroupCenter(therapyChildrenCenterDictionary.filter(data => data.type === "group"));

    }, []);


    return(
        <div className={"children-view container"}>
            <h3 className={"title-children-view"}>{findTitle(titles, "childrenTherapy", actualLanguage).toUpperCase()}</h3>
            <div className={"line-title-h3"}>{""}</div>
            <div className={"box-header-children"}>
                {headerChildren && <HeaderPhoto  headerAdults={headerChildren} type={"children"}/>}
                {headerChildrenPhoto && <img src={headerChildrenPhoto[0].img_url} alt="adults"/> }
            </div>
            {dataExplicationTherapyChildren && individualRight && familiarRight && socialRight && reeducationRight && groupRight &&
            individualCenter && familiarCenter && socialCenter && reeducationCenter && groupCenter &&
                dataExplicationTherapyChildren.map(dataTherapy =>{
                    return(
                        <div id={(dataTherapy.idText === urlParam) ? "explicationTherapyActive" : ""}>
                            <ExplicationTherapy img={dataTherapy.img_url} title={dataTherapy[`title_${actualLanguage}`]}
                                                dataCenter={centerInfoChildren}
                                                dataRight={rightInfoChildren}
                                                idText={dataTherapy.idText}
                                                urlParam={urlParam}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}