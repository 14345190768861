import React from "react";
import "./FAQSPrices.css"
import {FrequentQuestions} from "../FrequentsQuestions/FrequentQuestions";
import {Prices} from "../Prices/Prices";


export const FAQSPrices = props => {
    return(
        <div className={"container faqs-prices"}>
            <div className={"box-faqs-prices"}>
                <FrequentQuestions/>
            </div>
            <div className={"box-faqs-prices"}>
                <Prices/>
            </div>
        </div>
    )
}