export const therapyTeenagersRightDictionary = [
    {
        "id": 1,
        "text_cat": "Avaluaci\u00f3 i diagn\u00f2stic.",
        "text_es": "Evaluaci\u00f3n y diagn\u00f3stico.",
        "genderTherapy": 2,
        "location": 2,
        "type": "individual",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 1,
        "text_cat": "Devoluci\u00f3 dels resultats.",
        "text_es": "Devoluci\u00f3n de los resultados.",
        "genderTherapy": 2,
        "location": 2,
        "type": "individual",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 1,
        "text_cat": "Establir els objectius a treballar.",
        "text_es": "Establecer los objetivos a trabajar.",
        "genderTherapy": 2,
        "location": 2,
        "type": "individual",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 1,
        "text_cat": "Sessions individuals.",
        "text_es": "Sesiones individuales.",
        "genderTherapy": 2,
        "location": 2,
        "type": "individual",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 1,
        "text_cat": "T\u00e8cniques adaptades a cada persona.",
        "text_es": "T\u00e9cnicas adaptadas a cada persona.",
        "genderTherapy": 2,
        "location": 2,
        "type": "individual",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 1,
        "text_cat": "Enfoc global: pensament, emocions, sentiments, ment i cos.",
        "text_es": "Enfoque global: pensamiento, emociones, sentimientos, mente y cuerpo.",
        "genderTherapy": 2,
        "location": 2,
        "type": "individual",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 1,
        "text_cat": "Ajudar-te a comprendre\u2019t.",
        "text_es": "Ayudarte a comprenderte.",
        "genderTherapy": 2,
        "location": 2,
        "type": "individual",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 1,
        "text_cat": "Exercicis pr\u00e0ctics.",
        "text_es": "Ejercicios pr\u00e1cticos.",
        "genderTherapy": 2,
        "location": 2,
        "type": "individual",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 5,
        "text_cat": "Sessions individuals.",
        "text_es": "Sesiones individuales.",
        "genderTherapy": 2,
        "location": 2,
        "type": "social",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 5,
        "text_cat": "Activitats adaptades a cada dificultat i personalitat.",
        "text_es": "Actividades adaptadas a cada dificultad y personalidad.",
        "genderTherapy": 2,
        "location": 2,
        "type": "social",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 5,
        "text_cat": "Exercicis per agafar confian\u00e7a en un mateix.",
        "text_es": "Ejercicios para coger confianza en uno mismo.",
        "genderTherapy": 2,
        "location": 2,
        "type": "social",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 5,
        "text_cat": "Espai segur per a poder ajudar-te.",
        "text_es": "Espacio seguro para poder ayudarte.",
        "genderTherapy": 2,
        "location": 2,
        "type": "social",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 5,
        "text_cat": "Exercicis per a millorar la comunicaci\u00f3.",
        "text_es": "Ejercicios para mejorar la comunicaci\u00f3n.",
        "genderTherapy": 2,
        "location": 2,
        "type": "social",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 5,
        "text_cat": "Ajudar-te a comprendre els teus pensaments.",
        "text_es": "Ayudar a comprender tus pensamientos.",
        "genderTherapy": 2,
        "location": 2,
        "type": "social",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 5,
        "text_cat": "Exercicis pr\u00e0ctics.",
        "text_es": "Ejercicios pr\u00e1cticos.",
        "genderTherapy": 2,
        "location": 2,
        "type": "social",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 6,
        "text_cat": "Recolzament.",
        "text_es": "Apoyo.",
        "genderTherapy": 2,
        "location": 2,
        "type": "group",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 6,
        "text_cat": "Empatia.",
        "text_es": "Empat\u00eda.",
        "genderTherapy": 2,
        "location": 2,
        "type": "group",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 6,
        "text_cat": "Major satisfacci\u00f3.",
        "text_es": "Mayor satisfacci\u00f3n.",
        "genderTherapy": 2,
        "location": 2,
        "type": "group",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 6,
        "text_cat": "Companyerisme.",
        "text_es": "Compa\u00f1erismo.",
        "genderTherapy": 2,
        "location": 2,
        "type": "group",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 6,
        "text_cat": "Autoobservaci\u00f3.",
        "text_es": "Autoobservaci\u00f3n",
        "genderTherapy": 2,
        "location": 2,
        "type": "group",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 6,
        "text_cat": "Comprensi\u00f3.",
        "text_es": "Comprensi\u00f3n.",
        "genderTherapy": 2,
        "location": 2,
        "type": "group",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 6,
        "text_cat": "Sentir-se acompanyat.",
        "text_es": "Sentirse acompa\u00f1ado.",
        "genderTherapy": 2,
        "location": 2,
        "type": "group",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 6,
        "text_cat": "Ajuda m\u00fatua.",
        "text_es": "Ayuda mutua.",
        "genderTherapy": 2,
        "location": 2,
        "type": "group",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 3,
        "text_cat": "Espais per a tots.",
        "text_es": "Espacios para todos.",
        "genderTherapy": 2,
        "location": 2,
        "type": "familiar",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 3,
        "text_cat": "T\u00e8cniques adaptades a cada fam\u00edlia.",
        "text_es": "T\u00e9cnicas adaptadas a cada familia.",
        "genderTherapy": 2,
        "location": 2,
        "type": "familiar",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 3,
        "text_cat": "Aprendre a conviure.",
        "text_es": "Aprender a convivir.",
        "genderTherapy": 2,
        "location": 2,
        "type": "familiar",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 3,
        "text_cat": "Aprendre a escoltar-se i perdonar.",
        "text_es": "Aprender a escucharse y perdonarse.",
        "genderTherapy": 2,
        "location": 2,
        "type": "familiar",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 3,
        "text_cat": "Exercicis per a millorar la conviv\u00e8ncia.",
        "text_es": "Ejercicios para mejorar la convivencia.",
        "genderTherapy": 2,
        "location": 2,
        "type": "familiar",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 3,
        "text_cat": "Trobar formes de resoldre els conflictes.",
        "text_es": "Encontrar formas de resolver los conflictos.",
        "genderTherapy": 2,
        "location": 2,
        "type": "familiar",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 3,
        "text_cat": "Promoure l\u2019empatia i col\u00b7laboraci\u00f3.",
        "text_es": "Promover la empat\u00eda y colaboraci\u00f3n.",
        "genderTherapy": 2,
        "location": 2,
        "type": "familiar",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 3,
        "text_cat": "Promoure la sinceritat i confian\u00e7a.",
        "text_es": "Promover la sinceridad y confianza.",
        "genderTherapy": 2,
        "location": 2,
        "type": "familiar",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 7,
        "text_cat": "Aprendre a planificar-se i organitzar-se.",
        "text_es": "Aprender a planificarse y organizarse.",
        "genderTherapy": 2,
        "location": 2,
        "type": "reeducation",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 7,
        "text_cat": "Recuperaci\u00f3 dels aprenentatges.",
        "text_es": "Recuperaci\u00f3n de los aprendizajes.",
        "genderTherapy": 2,
        "location": 2,
        "type": "reeducation",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 7,
        "text_cat": "T\u00e8cniques d\u2019estudi.",
        "text_es": "T\u00e9cnicas de estudio.",
        "genderTherapy": 2,
        "location": 2,
        "type": "reeducation",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 7,
        "text_cat": "Treballar la motivaci\u00f3 i l\u2019esfor\u00e7.",
        "text_es": "Trabajar la motivaci\u00f3n y el esfuerzo.",
        "genderTherapy": 2,
        "location": 2,
        "type": "reeducation",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 7,
        "text_cat": "Treballar en l\u2019autoestima.",
        "text_es": "Trabajar en la autoestima.",
        "genderTherapy": 2,
        "location": 2,
        "type": "reeducation",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 7,
        "text_cat": "Reduir comportaments o conductes poc adequats.",
        "text_es": "Reducir comportamientos o conductas poco adecuados.",
        "genderTherapy": 2,
        "location": 2,
        "type": "reeducation",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 7,
        "text_cat": "Preparaci\u00f3 pels ex\u00e0mens.",
        "text_es": "Preparaci\u00f3n para los ex\u00e1menes.",
        "genderTherapy": 2,
        "location": 2,
        "type": "reeducation",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 7,
        "text_cat": "Planificaci\u00f3 d\u2019horaris.",
        "text_es": "Planificaci\u00f3n de horarios.",
        "genderTherapy": 2,
        "location": 2,
        "type": "reeducation",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 4,
        "text_cat": "Assessorament psicològic online.",
        "text_es": "Asesoramiento psicológico online.",
        "genderTherapy": 2,
        "location": 2,
        "type": "online",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 4,
        "text_cat": "Sessions individuals.",
        "text_es": "Sesiones individuales.",
        "genderTherapy": 2,
        "location": 2,
        "type": "online",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 4,
        "text_cat": "Sense despla\u00e7ament.",
        "text_es": "Sin desplazamiento.",
        "genderTherapy": 2,
        "location": 2,
        "type": "online",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 4,
        "text_cat": "Continu\u00eftat.",
        "text_es": "Continuidad.",
        "genderTherapy": 2,
        "location": 2,
        "type": "online",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 4,
        "text_cat": "Flexibilitat.",
        "text_es": "Flexibilidad.",
        "genderTherapy": 2,
        "location": 2,
        "type": "online",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 4,
        "text_cat": "Exercicis pr\u00e0ctics.",
        "text_es": "Ejercicios pr\u00e1cticos.",
        "genderTherapy": 2,
        "location": 2,
        "type": "online",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 4,
        "text_cat": "Possibilitat d'assit\u00e8ncia al centre.",
        "text_es": "Posibilidad de asistir al centro.",
        "genderTherapy": 2,
        "location": 2,
        "type": "online",
        "gender": "teenagers",
        "Location": "Right"
    },
    {
        "id": 4,
        "text_cat": "Discreci\u00f3.",
        "text_es": "Discreci\u00f3n.",
        "genderTherapy": 2,
        "location": 2,
        "type": "online",
        "gender": "teenagers",
        "Location": "Right"
    }
]