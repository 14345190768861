export const titlesDictionary = [
    {
        "id": 1,
        "idText": "appointmentFree",
        "title_cat": "Demanar primera cita",
        "title_es": "Pedir primera cita"
    },
    {
        "id": 2,
        "idText": "treatments",
        "title_cat": "Tractaments",
        "title_es": "Tratamientos"
    },
    {
        "id": 3,
        "idText": "FAQS",
        "title_cat": "Preguntes freq\u00fcents",
        "title_es": "Preguntas frecuentes"
    },
    {
        "id": 4,
        "idText": "prices",
        "title_cat": "Preus",
        "title_es": "Precios"
    },
    {
        "id": 5,
        "idText": "whereWeAre",
        "title_cat": "On estem",
        "title_es": "Donde estamos"
    },
    {
        "id": 6,
        "idText": "howToArrive",
        "title_cat": "Com arribar",
        "title_es": "Como llegar"
    },
    {
        "id": 7,
        "idText": "openingTime",
        "title_cat": "Horari",
        "title_es": "Horario"
    },
    {
        "id": 8,
        "idText": "adultsTherapy",
        "title_cat": "Ter\u00e0pies adults",
        "title_es": "Terapias adultos"
    },
    {
        "id": 9,
        "idText": "teenagersTherapy",
        "title_cat": "Ter\u00e0pies adolescents",
        "title_es": "Terapias adolescentes"
    },
    {
        "id": 10,
        "idText": "childrenTherapy",
        "title_cat": "Ter\u00e0pies infantils",
        "title_es": "Terapias infantiles"
    },
    {
        "id": 11,
        "idText": "whatWeDeal",
        "title_cat": "Qu\u00e8 tractem",
        "title_es": "Que tratamos"
    },
    {
        "id": 12,
        "idText": "howWeDeal",
        "title_cat": "Com ho tractem",
        "title_es": "Como lo tratamos"
    },
    {
        "id": 13,
        "idText": "contact",
        "title_cat": "Contacte",
        "title_es": "Contacto"
    },
    {
        "id": 14,
        "idText": "aboutUs",
        "title_cat": "Sobre nosaltres",
        "title_es": "Sobre nosotros"
    },
    {
        "id": 15,
        "idText": "weWantToHelp",
        "title_cat": "Volem ajudar-te",
        "title_es": "Queremos ayudarte"
    },
    {
        "id": 16,
        "idText": "office",
        "title_cat": "El centre",
        "title_es": "El centro"
    },
    {
        "id": 16,
        "idText": "mainTitle",
        "title_cat": "SOMPSIC PSICOLOGIA",
        "title_es": "SOMPSIC PSICOLOGÍA"
    }
]