export const therapyAdultsRightDictionary = [
    {
        "id": 1,
        "text_cat": "Avaluaci\u00f3 i diagn\u00f2stic.",
        "text_es": "Evaluaci\u00f3n y diagn\u00f3stico.",
        "genderTherapy": 1,
        "location": 2,
        "type": "individual",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 1,
        "text_cat": "Devoluci\u00f3 resultats.",
        "text_es": "Devoluci\u00f3n de los resultados.",
        "genderTherapy": 1,
        "location": 2,
        "type": "individual",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 1,
        "text_cat": "Establir els objectius a treballar.",
        "text_es": "Establecer los objetivos a trabajar.",
        "genderTherapy": 1,
        "location": 2,
        "type": "individual",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 1,
        "text_cat": "Sessions individuals.",
        "text_es": "Sesiones individuales.",
        "genderTherapy": 1,
        "location": 2,
        "type": "individual",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 1,
        "text_cat": "T\u00e8cniques adaptades a cada persona.",
        "text_es": "T\u00e9cnicas adaptadas a cada persona.",
        "genderTherapy": 1,
        "location": 2,
        "type": "individual",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 1,
        "text_cat": "Enfoc global: pensament, emocions, sentiments, ment i cos.",
        "text_es": "Enfoque global: pensamiento, emociones, sentimientos, mente y cuerpo.",
        "genderTherapy": 1,
        "location": 2,
        "type": "individual",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 1,
        "text_cat": "Ajudar-te a comprendre\u2019t.",
        "text_es": "Ayudarte a comprenderte.",
        "genderTherapy": 1,
        "location": 2,
        "type": "individual",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 1,
        "text_cat": "Exercicis pr\u00e0ctics.",
        "text_es": "Ejercicios pr\u00e1cticos.",
        "genderTherapy": 1,
        "location": 2,
        "type": "individual",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 2,
        "text_cat": "Sessions conjuntes i individuals.",
        "text_es": "Sesiones conjuntas e individuales.",
        "genderTherapy": 1,
        "location": 2,
        "type": "couple",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 2,
        "text_cat": "Aprendre a respectar-se.",
        "text_es": "Aprender a respetarse.",
        "genderTherapy": 1,
        "location": 2,
        "type": "couple",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 2,
        "text_cat": "T\u00e8cniques adaptades a cada parella.",
        "text_es": "T\u00e9cnicas adaptadas a cada pareja.",
        "genderTherapy": 1,
        "location": 2,
        "type": "couple",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 2,
        "text_cat": "Aprendre a resoldre les difer\u00e8ncies.",
        "text_es": "Aprender a resolver las diferencias.",
        "genderTherapy": 1,
        "location": 2,
        "type": "couple",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 2,
        "text_cat": "Recuperar l\u2019estima i la confian\u00e7a.",
        "text_es": "Recuperar la estima y la confianza.",
        "genderTherapy": 1,
        "location": 2,
        "type": "couple",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 2,
        "text_cat": "Millorar la comunicaci\u00f3 entre la parella.",
        "text_es": "Mejorar la comunicaci\u00f3n entre la pareja.",
        "genderTherapy": 1,
        "location": 2,
        "type": "couple",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 2,
        "text_cat": "Aprendre a respectar espais.",
        "text_es": "Aprender a respetar espacios.",
        "genderTherapy": 1,
        "location": 2,
        "type": "couple",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 2,
        "text_cat": "Gestionar les emocions i  els conflictes.",
        "text_es": "Gestionar las emociones y conflictos.",
        "genderTherapy": 1,
        "location": 2,
        "type": "couple",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 3,
        "text_cat": "Espais per a tots.",
        "text_es": "Espacios para todos.",
        "genderTherapy": 1,
        "location": 2,
        "type": "familiar",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 3,
        "text_cat": "T\u00e8cniques adaptades a cada fam\u00edlia.",
        "text_es": "T\u00e9cnicas adaptadas a cada familia.",
        "genderTherapy": 1,
        "location": 2,
        "type": "familiar",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 3,
        "text_cat": "Aprendre a conviure.",
        "text_es": "Aprender a convivir.",
        "genderTherapy": 1,
        "location": 2,
        "type": "familiar",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 3,
        "text_cat": "Aprendre a escoltar-se i perdonar.",
        "text_es": "Aprender a escucharse y perdonar.",
        "genderTherapy": 1,
        "location": 2,
        "type": "familiar",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 3,
        "text_cat": "Exercicis per a millorar la conviv\u00e8ncia.",
        "text_es": "Ejercicios para mejorar la convivencia.",
        "genderTherapy": 1,
        "location": 2,
        "type": "familiar",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 3,
        "text_cat": "Trobar formes de resoldre els conflictes.",
        "text_es": "Encontrar formas de resolver los conflictos.",
        "genderTherapy": 1,
        "location": 2,
        "type": "familiar",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 3,
        "text_cat": "Promoure l\u2019empatia i col\u00b7laboraci\u00f3.",
        "text_es": "Promover la empat\u00eda y colaboraci\u00f3n.",
        "genderTherapy": 1,
        "location": 2,
        "type": "familiar",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 3,
        "text_cat": "Promoure la sinceritat i confian\u00e7a.",
        "text_es": "Promover la sinceridad y confianza.",
        "genderTherapy": 1,
        "location": 2,
        "type": "familiar",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 4,
        "text_cat": "Assessorament psicològic online.",
        "text_es": "Asesoramiento psicológico online.",
        "genderTherapy": 1,
        "location": 2,
        "type": "online",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 4,
        "text_cat": "Sessions individuals.",
        "text_es": "Sesiones individuales.",
        "genderTherapy": 1,
        "location": 2,
        "type": "online",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 4,
        "text_cat": "Sense despla\u00e7ament.",
        "text_es": "Sin desplazamiento.",
        "genderTherapy": 1,
        "location": 2,
        "type": "online",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 4,
        "text_cat": "Continu\u00eftat.",
        "text_es": "Continuidad.",
        "genderTherapy": 1,
        "location": 2,
        "type": "online",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 4,
        "text_cat": "Flexibilitat.",
        "text_es": "Flexibilidad.",
        "genderTherapy": 1,
        "location": 2,
        "type": "online",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 4,
        "text_cat": "Exercicis pr\u00e0ctics.",
        "text_es": "Ejercicios pr\u00e1cticos.",
        "genderTherapy": 1,
        "location": 2,
        "type": "online",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 4,
        "text_cat": "Possibilitat d'assit\u00e8ncia al centre.",
        "text_es": "Posibilidad de asistir al centro.",
        "genderTherapy": 1,
        "location": 2,
        "type": "online",
        "gender": "adults",
        "Location": "Right"
    },
    {
        "id": 4,
        "text_cat": "Discreci\u00f3.",
        "text_es": "Discreci\u00f3n.",
        "genderTherapy": 1,
        "location": 2,
        "type": "online",
        "gender": "adults",
        "Location": "Right"
    }
]