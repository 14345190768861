import React from 'react'
import "../../../General/FormAppointment/FormAppointment.css"
import "./Presentation.css"
import {findTitle} from "../../../../Functions/FindTitle";
import {useLanguageContext} from "../../../../Context/LanguageContext";
import {useTitlesContext} from "../../../../Context/TitlesContext";

export const Presentation = () => {

    const {actualLanguage} = useLanguageContext()
    const {titles} = useTitlesContext()

    return(
        <div className={"presentation-container"}>
            <h3 className={"title-index-view"}>{findTitle(titles, "mainTitle", actualLanguage).toUpperCase()}</h3>
            <div className={"line-title-view-h3"}>{""}</div>
            <div className={'handsImg'}></div>
            <div className={"presentation-left"}>{""}</div>
            <div className="presentation-right">
            </div>
        </div>
    )
}