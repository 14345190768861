import React, {useState} from "react";
import {Link} from "react-router-dom"
import "./HeaderDropDown.css"
import {useLanguageContext} from "../../../../../Context/LanguageContext";

export const HeaderDropDown = props => {

    const {dataContextDropDown, menuContext} = props

    const {actualLanguage} = useLanguageContext()

    const [dropDown, setDropDown] = useState(false)

    const dropDownOn = () => setDropDown(true);
    const dropDownOff = () => setDropDown(false);

    return(
        <div className={"cursor-drop-down"} onMouseEnter={ () => dropDownOn()} onMouseLeave={ () => dropDownOff()}>
            {((menuContext.idText === "adults") || (menuContext.idText === "teenagers") || (menuContext.idText === "children")) &&
                <Link to={`/${menuContext.idText}/all`} className={"link-bottom-header-text"}><p className="bottom-header-text">{menuContext[`contextMenu_${actualLanguage}`].toUpperCase()}</p></Link>
            }
            {((menuContext.idText === "contact") || (menuContext.idText === "aboutUs")) &&
                <Link to={`/${menuContext.idText}`} className={"link-bottom-header-text"}><p className="bottom-header-text">{menuContext[`contextMenu_${actualLanguage}`].toUpperCase()}</p></Link>
            }
            {(dropDown === true) && (dataContextDropDown !== undefined) &&
            <ul className="drop-down-list" >
                {dataContextDropDown.map((context) => {
                    return(
                        <div>
                            <Link to={`/${menuContext.idText}/${context.idText}`} className={"link-list-text"}><li className={"list-text"}>{context[`name_${actualLanguage}`]}</li></Link>
                        </div>
                    )
                })}
            </ul>
            }
        </div>
    )
}

