import React, {createContext} from "react";
import {FooterTop} from "./FooterTop/FooterTop";
import {FooterBottom} from "./FooterBottom/FooterBottom";

export const TitlesFooterContext = createContext();

export const Footer = props => {

    return(
        <div className={"footer-view"}>
            <FooterTop/>
            <FooterBottom/>
        </div>
    )
}