export const pricesDictionary = [
    {
        "id": 1,
        "idText": "individual",
        "textPrice_cat": "Individual",
        "textPrice_es": "Individual",
        "Price": 60,
        "priceText_cat": null,
        "priceText_es": null
    },
    {
        "id": 2,
        "idText": "partner",
        "textPrice_cat": "Parella",
        "textPrice_es": "Pareja",
        "Price": 65,
        "priceText_cat": null,
        "priceText_es": null
    },
    {
        "id": 3,
        "idText": "family",
        "textPrice_cat": "Familiar",
        "textPrice_es": "Familiar",
        "Price": 70,
        "priceText_cat": null,
        "priceText_es": null
    },
    {
        "id": 4,
        "idText": "online",
        "textPrice_cat": "Online",
        "textPrice_es": "Online",
        "Price": 60,
        "priceText_cat": null,
        "priceText_es": null
    }
]