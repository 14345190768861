import React from "react";
import "./DrawerMenuCall.css"
import { Drawer } from '@material-ui/core';
import {DrawerMenuContext} from "./DrawerMenuContext/DrawerMenuContext";

export const DrawerMenuCall = props =>{

    const {dropDownDrawerMenu, closeDrawerMenu, dataBottomHeaderRight, allDataDropDown} = props



    return(
        <div>
            <Drawer anchor={"right"} open={dropDownDrawerMenu} onClose={closeDrawerMenu}
                    children={<DrawerMenuContext dataBottomHeaderRight={dataBottomHeaderRight} allDataDropDown={allDataDropDown}
                                                 closeDrawerMenu={closeDrawerMenu}/>}/>
        </div>
    )
}