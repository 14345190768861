export const textFooterBottomDictionary = [
    {
        "id": 1,
        "idText": "legalWarning",
        "context_cat": "Av\u00eds legal",
        "context_es": "Aviso legal"
    },
    {
        "id": 2,
        "idText": "privacity",
        "context_cat": "Pol\u00edtica de privacitat",
        "context_es": "Pol\u00edtica de privacidad"
    },
    {
        "id": 3,
        "idText": "cookies",
        "context_cat": "Pol\u00edtica de cookies",
        "context_es": "Pol\u00edtica de cookies"
    }
]