export const explicationTherapyTeenagersDictionary = [
    {
        "id": 2,
        "idText": "teenagersIndividual",
        "img_url": "https://cdn.pixabay.com/photo/2016/11/14/05/29/girl-1822702_960_720.jpg",
        "title_cat": "Psicoteràpia individual",
        "title_es": "Psicoterapia individual",
        "gender_id": 2,
        "gender": "teenagers"
    },
    {
        "id": 2,
        "idText": "teenagersSocial",
        "img_url": "https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
        "title_cat": "Habilitats socials",
        "title_es": "Habilidades sociales",
        "gender_id": 2,
        "gender": "teenagers"
    },
    {
        "id": 2,
        "idText": "teenagersFamiliar",
        "img_url": "https://images.unsplash.com/photo-1569073120512-05362a6b92e7?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
        "title_cat": "Psicoteràpia familiar",
        "title_es": "Psicoterapia familiar",
        "gender_id": 2,
        "gender": "teenagers"
    },
    {
        "id": 2,
        "idText": "teenagersReeducation",
        "img_url": "https://images.unsplash.com/photo-1535982330050-f1c2fb79ff78?ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80",
        "title_cat": "Reeducacions",
        "title_es": "Reeducaciones",
        "gender_id": 2,
        "gender": "teenagers"
    },
    {
        "id": 2,
        "idText": "teenagersOnline",
        "img_url": "https://cdn.pixabay.com/photo/2019/06/06/16/02/technology-4256272_960_720.jpg",
        "title_cat": "Psicoteràpia online",
        "title_es": "Psicoterapia online",
        "gender_id": 2,
        "gender": "teenagers"
    }
]