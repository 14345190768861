import React from 'react'
import "./ActualLanguage.css"
import {useLanguageContext} from "../../../../../../Context/LanguageContext";

export const Language = props => {

    const {Language} = props

    const {actualLanguage, setActualLanguage} = useLanguageContext()

    return(
        <p className={`language-text ${(actualLanguage === Language.language)? "active" : ""}`}
           onClick={() => setActualLanguage(Language.language)}>{Language.languageText.toUpperCase()}</p>
    )
}