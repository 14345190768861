export const explicationTherapyChildrenDictionary = [
    {
        "id": 3,
        "idText": "childrenIndividual",
        "img_url": "https://images.unsplash.com/photo-1526958097901-5e6d742d3371?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
        "title_cat": "Psicoteràpia individual",
        "title_es": "Psicoterapia individual",
        "gender_id": 3,
        "gender": "children"
    },
    {
        "id": 3,
        "idText": "childrenFamiliar",
        "img_url": "https://cdn.pixabay.com/photo/2018/08/13/03/21/woman-3602245_960_720.jpg",
        "title_cat": "Psicoteràpia familiar",
        "title_es": "Psicoterapia familiar",
        "gender_id": 3,
        "gender": "children"
    },
    {
        "id": 3,
        "idText": "childrenSocial",
        "img_url": "https://images.unsplash.com/photo-1502086223501-7ea6ecd79368?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1023&q=80",
        "title_cat": "Habilitats socials",
        "title_es": "Habilidades sociales",
        "gender_id": 3,
        "gender": "children"
    },
    {
        "id": 3,
        "idText": "childrenReeducation",
        "img_url": "https://images.unsplash.com/photo-1560785496-3c9d27877182?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=80",
        "title_cat": "Reeducacions",
        "title_es": "Reeducaciones",
        "gender_id": 3,
        "gender": "children"
    }
]