import React, { useEffect, useState} from "react";
import "./AdultsView.css"
import {ExplicationTherapy} from "../../General/ExplicationTherapy/ExplicationTherapy";
import {HeaderPhoto} from "../../General/HeaderPhoto/HeaderPhoto";
import {useLanguageContext} from "../../../Context/LanguageContext";
import {useTitlesContext} from "../../../Context/TitlesContext";
import {findTitle} from "../../../Functions/FindTitle";
import {headerBottomTitleAdultsDictionary} from "../../../dictionaries/header/headerBottomTitleAdults";
import {explicationTherapyAdultsDictionary} from "../../../dictionaries/therapyDetails/therapyAdults/explicationTherapyAdults";
import {explicationTherapyHeaderPhotoAdultsDictionary} from "../../../dictionaries/therapyDetails/therapyAdults/explicationTherapyHeaderPhotoAdults";
import {therapyAdultsRightDictionary} from "../../../dictionaries/therapyDetails/therapyAdults/therapyAdultsRight";
import {therapyAdultsCenterDictionary} from "../../../dictionaries/therapyDetails/therapyAdults/therapyAdultsCenter";

export const AdultsView = props =>{

    const {match} = props

    const urlParam = match.params["open"];

    const {actualLanguage} = useLanguageContext()
    const {titles} = useTitlesContext()

    const [dataExplicationTherapyAdults, setDataExplicationTherapyAdults] = useState(undefined);
    const [individualRight, setIndividualRight] = useState(undefined);
    const [coupleRight, setCoupleRight] = useState(undefined);
    const [familiarRight, setFamiliarRight] = useState(undefined);
    const [onlineRight, setOnlineRight] = useState(undefined);
    const [individualCenter, setIndividualCenter] = useState(undefined);
    const [coupleCenter, setCoupleCenter] = useState(undefined);
    const [familiarCenter, setFamiliarCenter] = useState(undefined);
    const [onlineCenter, setOnlineCenter] = useState(undefined);
    const [headerAdults, setHeaderAdults] = useState(undefined);
    const [headerAdultsPhoto, setHeaderAdultsPhoto] = useState(undefined);

    const rightInfoAdults = {"adultsIndividual": individualRight, "adultsCouple": coupleRight, "adultsFamiliar": familiarRight, "adultsOnline": onlineRight};
    const centerInfoAdults = {"adultsIndividual": individualCenter, "adultsCouple": coupleCenter, "adultsFamiliar": familiarCenter, "adultsOnline": onlineCenter};

    useEffect(() =>{
        setHeaderAdults(headerBottomTitleAdultsDictionary);
        setDataExplicationTherapyAdults(explicationTherapyAdultsDictionary);
        setHeaderAdultsPhoto(explicationTherapyHeaderPhotoAdultsDictionary);

        setIndividualRight(therapyAdultsRightDictionary.filter(data => data.type === "individual"));
        setCoupleRight(therapyAdultsRightDictionary.filter(data => data.type === "couple"));
        setFamiliarRight(therapyAdultsRightDictionary.filter(data => data.type === "familiar"));
        setOnlineRight(therapyAdultsRightDictionary.filter(data => data.type === "online"));

        setIndividualCenter(therapyAdultsCenterDictionary.filter(data => data.type === "individual"));
        setCoupleCenter(therapyAdultsCenterDictionary.filter(data => data.type === "couple"));
        setFamiliarCenter(therapyAdultsCenterDictionary.filter(data => data.type === "familiar"));
        setOnlineCenter(therapyAdultsCenterDictionary.filter(data => data.type === "online"));

    }, []);


    return(
        <div className={"adults-view container"}>
            <h3 className={"title-adults-view"}>{findTitle(titles, "adultsTherapy", actualLanguage).toUpperCase()}</h3>
            <div className={"line-title-h3"}>{""}</div>
            <div className={"box-header-adults"}>
                {headerAdults && <HeaderPhoto  headerAdults={headerAdults} type={"adults"}/>}
                {headerAdultsPhoto && <img src={headerAdultsPhoto[0].img_url} alt="adults"/> }
            </div>
            {dataExplicationTherapyAdults && individualRight && coupleRight && familiarRight &&
            onlineRight && individualCenter && coupleCenter && familiarCenter && onlineCenter &&
                dataExplicationTherapyAdults.map(dataTherapy =>{
                return(
                    <div id={(dataTherapy.idText === urlParam) ? "explicationTherapyActive" : ""}>
                        <ExplicationTherapy img={dataTherapy.img_url} title={dataTherapy[`title_${actualLanguage}`]}
                                            dataCenter={centerInfoAdults}
                                            dataRight={rightInfoAdults}
                                            idText={dataTherapy.idText}
                                            urlParam={urlParam}
                        />
                    </div>
                )
                })
            }
        </div>
    )
}