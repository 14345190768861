import React from "react";
import "./TreatmentLabel.css"
import {useLanguageContext} from "../../../../../Context/LanguageContext";



export const TreatmentLabel = props => {

    const {dataTreatmentLabel} = props

    const {actualLanguage} = useLanguageContext()

    return(
        <div className={"treatments"}>
            {dataTreatmentLabel.map(valor => {
                return(
                    <div className={"scene"}>
                        <div className={"card"}>
                            <div className={"card-face-front"}>
                                <img className={"card-image"} src={valor.imgUrl} alt="Anxiety"/>
                                <div className={"box-text-frond"}><p className={"card-text-frond"}>{valor[`nameFront_${actualLanguage}`].toUpperCase()}</p></div>
                            </div>
                            <div className={"card-face-back"}>
                                <div className={"box-text-back"}><p className={"card-text-back"}>{valor[`contextBack_${actualLanguage}`]}</p></div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
