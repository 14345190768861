import React, {useState} from "react"
import "./Question.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import {useLanguageContext} from "../../../../../../Context/LanguageContext";


export const Question = props => {

    const {data, index} = props

    const {actualLanguage} = useLanguageContext()

    const [dropDown, setDropDown]= useState(false)

    const ifaChevronDown = <FontAwesomeIcon className="icon icon-size-ChevronDown" icon={faChevronDown} />
    const ifaChevronUp = <FontAwesomeIcon className="icon icon-size-ChevronUp" icon={faChevronUp} />

    const dropDownOnOff = () => setDropDown(!dropDown);

    return(
        <div key={index}  className={"questions-context"}>
            <div className={`question ${(dropDown === true)? "active" : ""}`} onClick={() => dropDownOnOff()}>
                <div>{data[`question_${actualLanguage}`]}</div>
                {(dropDown === true) ? <div>{ifaChevronUp}</div> : <div>{ifaChevronDown}</div>}
            </div>
            {(dropDown === true) &&
                <div className={"response"}>
                    <p>{data[`paragraphOne_${actualLanguage}`]}</p>
                    <p>{data[`paragraphTwo_${actualLanguage}`]}</p>
                </div>
            }
        </div>
    )
}