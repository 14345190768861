import React, {useState} from "react";
import "./FooterBottomContext.css"
import {useLanguageContext} from "../../../../../Context/LanguageContext";
import {InfoModal} from "../../../InfoModal/InfoModal";

export const FooterBottomContext = props => {

    const {dataTextFooterBottom} = props

    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [type, setType] = useState('');

    const {actualLanguage} = useLanguageContext();

    const year = new Date().getFullYear();

    const handleAdvertismentClick = (type) => {
        setOpenInfoModal(true);
        setType(type);
    }

    const closeInfoModal = () => {
        setOpenInfoModal(false)
    }

    return(
        <div className={"bottom-footer"}>
            <div>
                <p>Copyright &copy; SomPsic {year}</p>
            </div>
            <div className={"flex-bottom-text"}>
                {dataTextFooterBottom.map( data =>{
                    return(
                        <React.Fragment>
                            <p className={'advertisments'} onClick={() => handleAdvertismentClick((data['context_cat']))}>{data[`context_${actualLanguage}`]}</p>
                            <InfoModal type={type}
                                       closeInfoModal={closeInfoModal} openInfoModal={openInfoModal}/>
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
}