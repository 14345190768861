export const headerBottomTitleAdultsDictionary = [
    {
        "id": 1,
        "idText": "adultsIndividual",
        "name_cat": "Psicoter\u00e0pia individual",
        "name_es": "Psicoterapia individual",
        "gender_id": 1,
        "gender": "adults"
    },
    {
        "id": 1,
        "idText": "adultsCouple",
        "name_cat": "Psicoter\u00e0pia de parella",
        "name_es": "Psicoterapia de pareja",
        "gender_id": 1,
        "gender": "adults"
    },
    {
        "id": 1,
        "idText": "adultsFamiliar",
        "name_cat": "Psicoter\u00e0pia familiar",
        "name_es": "Psicoterapia familiar",
        "gender_id": 1,
        "gender": "adults"
    },
    {
        "id": 1,
        "idText": "adultsOnline",
        "name_cat": "Psicoter\u00e0pia online",
        "name_es": "Psicoterapia online",
        "gender_id": 1,
        "gender": "adults"
    }
]