import React, {useState, useContext, createContext} from "react"

const LanguageContext = createContext();

export const LanguageContextProvider = ({children}) => {

    const [actualLanguage, setActualLanguage] = useState("cat");

    return(
        <LanguageContext.Provider value={{actualLanguage, setActualLanguage}}>
            {children}
        </LanguageContext.Provider>
    )
}

export const useLanguageContext = () => useContext(LanguageContext);