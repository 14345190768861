import React, {useEffect, useState} from "react";
import "./FooterTop.css"
import {FooterTopContext} from "./FooterTopContext/FooterTopContext.js"
import {textFooterTopDictionary} from "../../../../dictionaries/footer/textFooterTop";

export const FooterTop = props =>{

    const [dataTextFooter, setDataTextFooter] = useState(undefined)

    useEffect(()=> {
        setDataTextFooter(textFooterTopDictionary);
    }, []);

    return(
        <div>
        {dataTextFooter && <FooterTopContext dataTextFooter={dataTextFooter}/>}
        </div>
    )
}