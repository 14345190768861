import React, {useEffect, useState} from 'react'
import {ContextFrequentQuestions} from "./ContextFrequentQuestions/ContextFrequentQuestions.js"
import "./FrequentQuestions.css"
import {frequentQuestionsDictionary} from "../../../../dictionaries/FAQs/frequentQuestionsDictionary";

export const FrequentQuestions = props => {

    const [dataFrequentQuestions, SetDataFrequentQuestions] = useState(undefined);


    useEffect(() =>{
        SetDataFrequentQuestions(frequentQuestionsDictionary);
    }, []);


    return(
        <div>
            {dataFrequentQuestions && <ContextFrequentQuestions dataFrequentQuestions={dataFrequentQuestions}/>}
        </div>
    )
}