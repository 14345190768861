export const languagesDictionary = [
    {
        "id":1,
        "language":"cat",
        "languageText":"cat"
    },
    {
        "id":2,
        "language":"es",
        "languageText":"es"
    }
]