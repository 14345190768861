import React, {useEffect} from "react"
import {Presentation} from "./Presentation/Presentation.js";
import {Treatments} from "./Treatments/Treatments.js";
import {Location} from "../../General/Location/Location.js";
import {FAQSPrices} from "./FAQSPrices/FAQSPrices.js";
import './index.css';
import ReactGA from "react-ga";

export const Index = props => {

    useEffect(() => {
        ReactGA.pageview('/');
    }, [])

    return(
        <div>
            <Presentation/>
            <h1 className={'description-hide'}>Centro de Psicología en Esplugues de Llobregat, Barcelona. En SomPsic trabajamos para ayudarte a conseguir tus objetivos y así conseguir un nivel satisfactorio de bienestar personal.</h1>
            <p className={'description-hide'}>Centro de Psicología en Esplugues de Llobregat, Barcelona. En SomPsic trabajamos para ayudarte a conseguir tus objetivos y así conseguir un nivel satisfactorio de bienestar personal.</p>
            <Treatments />
            <FAQSPrices/>
            <Location/>
        </div>
    )
}