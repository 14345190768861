import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {Languages} from "./Lenguages/Languages"
import {languagesDictionary} from "../../../../dictionaries/lenguages/languages";

export const TopHeader = props =>{

    const [dataLanguages, setDataLanguages] = useState(undefined);

    const ifaEnvelope = <FontAwesomeIcon className="icon icon-envelope" icon={faEnvelope} />
    const ifaPhone = <FontAwesomeIcon className="icon icon-phone" icon={faPhone} />

    useEffect(()=> {
        setDataLanguages(languagesDictionary);
    }, []);

    return(
        <div className="top-header">
            <div className="top-header-context">
                <div className="top-header-left">
                    <div className={"box-context-header-left"}>
                        <div className="i-mobileAlt"> {ifaPhone}</div>
                        <a href="tel:667558101">667558101</a>
                    </div>
                    <div className={"separator"}>{""}</div>
                    <div className={"box-context-header-left"}>
                        <div className="i-mobileAlt"> {ifaEnvelope}</div>
                        <p>psicologia@sompsic.com</p>
                    </div>

                </div>
                <div className="top-header-right">
                    {dataLanguages && <Languages dataLanguages={dataLanguages}/>}
                </div>
            </div>
        </div>
    )
}