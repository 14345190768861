import React, {useEffect, useState} from 'react'
import "./AboutUs.css"
import {AboutUsContext} from "./AboutUsContext/AboutUsContext";
import ReactGA from "react-ga";
import {aboutUSInformationDictionary} from "../../../dictionaries/aboutUsInformation/aboutUSInformation";

export const AboutUs = props => {

    const [dataAboutUs, setDataAboutUs] = useState(undefined);

    useEffect(()=> {
        setDataAboutUs(aboutUSInformationDictionary);
        ReactGA.pageview('/aboutUs');
    }, []);

    return(
        <div className={"aboutUs-view container"}>
            {dataAboutUs && <AboutUsContext dataAboutUs={dataAboutUs}/>}
        </div>
    )
}