export const therapyTeenagersCenterDictionary = [
    {
        "id": 1,
        "text_cat": "Depressi\u00f3.",
        "text_es": "Depresi\u00f3n.",
        "genderTherapy": 2,
        "location": 1,
        "type": "individual",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 1,
        "text_cat": "Ansietat.",
        "text_es": "Ansiedad.",
        "genderTherapy": 2,
        "location": 1,
        "type": "individual",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 1,
        "text_cat": "Problemes emocionals.",
        "text_es": "Problemas emocionales.",
        "genderTherapy": 2,
        "location": 1,
        "type": "individual",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 1,
        "text_cat": "Problemes en les relacions socials.",
        "text_es": "Problemas en las relaciones sociales.",
        "genderTherapy": 2,
        "location": 1,
        "type": "individual",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 1,
        "text_cat": "F\u00f2bies.",
        "text_es": "Fobias.",
        "genderTherapy": 2,
        "location": 1,
        "type": "individual",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 1,
        "text_cat": "Obsessions.",
        "text_es": "Obsesiones.",
        "genderTherapy": 2,
        "location": 1,
        "type": "individual",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 1,
        "text_cat": "Dols.",
        "text_es": "Duelos.",
        "genderTherapy": 2,
        "location": 1,
        "type": "individual",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 5,
        "text_cat": "Escoltar als altres.",
        "text_es": "Escuchar a los dem\u00e1s.",
        "genderTherapy": 2,
        "location": 1,
        "type": "social",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 5,
        "text_cat": "Empatitzar.",
        "text_es": "Empatizar.",
        "genderTherapy": 2,
        "location": 1,
        "type": "social",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 5,
        "text_cat": "Ser assertiu.",
        "text_es": "Ser asertivo.",
        "genderTherapy": 2,
        "location": 1,
        "type": "social",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 5,
        "text_cat": "Negociar.",
        "text_es": "Negociar.",
        "genderTherapy": 2,
        "location": 1,
        "type": "social",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 5,
        "text_cat": "Saber dir que no.",
        "text_es": "Saber decir que no.",
        "genderTherapy": 2,
        "location": 1,
        "type": "social",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 5,
        "text_cat": "Respectar els altres.",
        "text_es": "Respetar a los dem\u00e1s.",
        "genderTherapy": 2,
        "location": 1,
        "type": "social",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 5,
        "text_cat": "Establir converses.",
        "text_es": "Establecer conversaciones.",
        "genderTherapy": 2,
        "location": 1,
        "type": "social",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 5,
        "text_cat": "Relacionar-te amb companys.",
        "text_es": "Relacionarse con compa\u00f1eros.",
        "genderTherapy": 2,
        "location": 1,
        "type": "social",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 6,
        "text_cat": "A vegades \u00e9s necessari treballar en grup per a poder desenvolupar-se i millorar. Veure que el teu mateix problema, tamb\u00e9 li passa a altres persones et pot ajudar a deixar-te anar i sentir-te compr\u00e8s.",
        "text_es": "A veces es necesario trabajar en grupo para poder desarrollarse y mejorar. Ver que tu mismo problema, tambi\u00e9n les pasa a otras personas te puede ayudar a dejarte ir y sentirte comprendido.",
        "genderTherapy": 2,
        "location": 1,
        "type": "group",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 3,
        "text_cat": "Relacions familiars conflictives.",
        "text_es": "Relaciones familiares conflictivas.",
        "genderTherapy": 2,
        "location": 1,
        "type": "familiar",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 3,
        "text_cat": "Viol\u00e8ncia dom\u00e8stica.",
        "text_es": "Violencia dom\u00e9stica.",
        "genderTherapy": 2,
        "location": 1,
        "type": "familiar",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 3,
        "text_cat": "Problemes en les relacions amb els fills.",
        "text_es": "Problemas en las relaciones con los hijos.",
        "genderTherapy": 2,
        "location": 1,
        "type": "familiar",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 3,
        "text_cat": "Separacions.",
        "text_es": "Separaciones.",
        "genderTherapy": 2,
        "location": 1,
        "type": "familiar",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 3,
        "text_cat": "Problemes de comunicaci\u00f3.",
        "text_es": "Problemas de comunicaci\u00f3n.",
        "genderTherapy": 2,
        "location": 1,
        "type": "familiar",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 3,
        "text_cat": "Esdeveniments estressants.",
        "text_es": "Eventos estresantes.",
        "genderTherapy": 2,
        "location": 1,
        "type": "familiar",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 7,
        "text_cat": "Problemes a l\u2019escola?",
        "text_es": "\u00bfProblemas en la escuela?",
        "genderTherapy": 2,
        "location": 1,
        "type": "reeducation",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 7,
        "text_cat": "Busques millorar el rendiment escolar?",
        "text_es": "\u00bfBuscas mejorar el rendimiento escolar?",
        "genderTherapy": 2,
        "location": 1,
        "type": "reeducation",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 7,
        "text_cat": "Assignatures impossibles.",
        "text_es": "Asignaturas imposibles.",
        "genderTherapy": 2,
        "location": 1,
        "type": "reeducation",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 7,
        "text_cat": "Trastorns d\u2019aprenentatge.",
        "text_es": "Trastornos de aprendizaje.",
        "genderTherapy": 2,
        "location": 1,
        "type": "reeducation",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 4,
        "text_cat": "Depressi\u00f3.",
        "text_es": "Depresi\u00f3n.",
        "genderTherapy": 2,
        "location": 1,
        "type": "online",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 4,
        "text_cat": "Ansietat.",
        "text_es": "Ansiedad.",
        "genderTherapy": 2,
        "location": 1,
        "type": "online",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 4,
        "text_cat": "Estr\u00e9s.",
        "text_es": "Estr\u00e9s.",
        "genderTherapy": 2,
        "location": 1,
        "type": "online",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 4,
        "text_cat": "Problemes emocionals.",
        "text_es": "Problemas emocionales.",
        "genderTherapy": 2,
        "location": 1,
        "type": "online",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 4,
        "text_cat": "F\u00f2bies.",
        "text_es": "Fobias.",
        "genderTherapy": 2,
        "location": 1,
        "type": "online",
        "gender": "teenagers",
        "Location": "Center"
    },
    {
        "id": 4,
        "text_cat": "etc...",
        "text_es": "etc...",
        "genderTherapy": 2,
        "location": 1,
        "type": "online",
        "gender": "teenagers",
        "Location": "Center"
    }
]