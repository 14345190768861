import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import './App.css';
import "./Css/General.css"
import {ScrollToUp} from "./Components/ScrollToUp/ScrollToUp";
import {Header} from "./Components/General/Header/Header.js"
import {Index} from "./Components/Views/Index/Index";
import {AdultsView} from "./Components/Views/AdultsView/AdultsView";
import {Footer} from "./Components/General/Footer/Footer";
import {TeenagersView} from "./Components/Views/TeenagersView/TeenagersView";
import {ChildrenView} from "./Components/Views/ChildrenView/ChildrenView";
import {LanguageContextProvider} from "./Context/LanguageContext";
import {Contact} from "./Components/Views/Contact/Contact";
import {TitlesContextProvider} from "./Context/TitlesContext";
import {AboutUs} from "./Components/Views/AboutUs/AboutUs";
import ReactGA from 'react-ga';

const App = () => {

    useEffect(() => {
        ReactGA.initialize('UA-184000908-1')
    }, [])

  return (
      <LanguageContextProvider>
          <TitlesContextProvider>
              <Router>
              <Helmet>
                  <title>{'SomPsic Psicologia'}</title>
                  <meta name="image" content={'/favicon.ico>'}/>
                  <meta name="description" content={'Centro de Psicología en Esplugues de Llobregat, Barcelona. En SomPsic trabajamos para ayudarte a conseguir tus objetivos y así conseguir un nivel satisfactorio de bienestar personal.'} />
                  <meta name="keywords" content={'Esplugues de Llobregat Psicologia Psicoleg Psicologo'} />
                  <link rel="canonical" href={'https://sompsic.com'} />
              </Helmet>
            <div className={"web-view"}>
                <Route render={props => <ScrollToUp {...props}/>}/>
                <Route><Header/></Route>
                <Switch>
                    <Route exact path={"/"} render={props => <Index {...props}/>}/>
                    <Route exact path={"/adults/:open"} render={props => <AdultsView {...props} />}/>
                    <Route exact path={"/teenagers/:open"} render={props => <TeenagersView {...props} />}/>
                    <Route exact path={"/children/:open"} render={props => <ChildrenView {...props} />}/>
                    <Route exact path={"/contact"} render={props => <Contact {...props} />}/>
                    <Route exact path={"/aboutUs"} render={props => <AboutUs {...props} />}/>
                </Switch>
                <Route><Footer/></Route>
            </div>
          </Router>
          </TitlesContextProvider>
      </LanguageContextProvider>
  )
}
export default App;
