import React from "react";
import "./Office.css"
import {useLanguageContext} from "../../../../../Context/LanguageContext";
import {useTitlesContext} from "../../../../../Context/TitlesContext";
import {findTitle} from "../../../../../Functions/FindTitle";
import butaques from '../../../../../Img/butaques.jpg';
import mesita from '../../../../../Img/mesita.jpg';
import espera1 from '../../../../../Img/espera1.jpg';
import espera2 from '../../../../../Img/espera2.jpg';

export const Office = props => {

    const {dataAboutUs} = props

    const {actualLanguage} = useLanguageContext()
    const {titles} = useTitlesContext()

    return(
        <div className={"box-office"}>
            <h3 className={"title-aboutUs-help"}>{findTitle(titles, "office", actualLanguage).toUpperCase()}</h3>
            <div className={"line-title-h3"}>{""}</div>
            <div className={"box-office-img"}>
                <div className={"img-office"}><img src={butaques} alt="office"/></div>
                <div className={"img-office"}><img src={mesita} alt="office"/></div>
                <div className={"img-office"}><img src={espera1} alt="office"/></div>
                <div className={"img-office"}><img src={espera2} alt="office"/></div>
            </div>
            <div className={"box-text-office"}>
                <p>{dataAboutUs[0][`officeParagraphOne_${actualLanguage}`]}</p>
                <p>{dataAboutUs[0][`officeParagraphTwo_${actualLanguage}`]}</p>
            </div>
        </div>
    )
}